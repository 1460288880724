import React, { Component } from 'react';





  class ScreenLoading extends Component {



  constructor(props) {
    super(props);
    this.state = {
    };
  }
    
    componentDidMount() {
    
   }

   componentDidUpdate(prevProps) {
    
    }

   componentWillUnmount() {

    
 }
 

    
    
    render(){




  return (

    <div className="LoadingScreen">     

    LOADING
      
    </div>
 
    );
    }
  }

  export default ScreenLoading;