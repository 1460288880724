import React, { Component } from 'react';
//import './Overlay.css';

  class Datenschutz extends Component {
    render(){
     

  return (

    <div className={this.props.className}> {/** AnimateIn and Out */}
       <div className="ImpressumButton" onClick={()=>{this.props.handleCloseBtnClick("Datenschutz")}}>X</div>
       <p><strong>Allgemeines</strong></p>
<p>Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt stets im Einklang mit dem Kirchlichen Datenschutzgesetz (KDG). Mittels dieser Datenschutzerklärung möchten wir die Öffentlichkeit über Art, Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren. Ferner werden betroffene Personen mittels dieser Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.</p>
 <p><strong>Information über die Erhebung personenbezogener Daten</strong></p>
<p>(1) Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung unserer Website. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z.B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.</p>
<p>(2) Verantwortlicher gem. § 4 Nr. 9 des Gesetzes über den kirchlichen Datenschutz (KDG) ist das Bistum Hildesheim&nbsp;<br />
vertreten durch die Direktorin Prof. Dr. Claudia Höhl&nbsp;<br />
Domhof 18-21<br />
31134 Hildesheim&nbsp;<br />
Tel. (05121) 307 760&nbsp;<br />
E-Mail <a href="mailto:dommuseum@bistum-hildesheim.de">dommuseum@bistum-hildesheim.de</a></p>
<p>Unseren Datenschutzbeauftragten erreichen Sie unter&nbsp;<br />
Datenschutz Nord GmbH&nbsp;<br />
Konsul-Smidt-Straße 88&nbsp;<br />
28217 Bremen&nbsp;<br />
vertreten durch den Geschäftsführer Dr. Uwe Schläger&nbsp;<br />
Tel. (0421) 696 632-0&nbsp;<br />
E-Mail: <a href="mailto:office@datenschutz-nord.de">office@datenschutz-nord.de</a>&nbsp;<br />
Internet: <a href="http://www.datenschutz-nord-gruppe.de">www.datenschutz-nord-gruppe.de</a></p>
<p>(3) Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</p>
<p>(4) Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen möchten, werden wir Sie untenstehend im Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.</p>
<p><strong>Ihre Rechte</strong></p>
<p>(1) Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:</p>
<ul>
<li>Recht auf Auskunft</li>
<li>Recht auf Berichtigung oder Löschung</li>
<li>Recht auf Einschränkung der Verarbeitung</li>
<li>Recht auf Widerspruch gegen die Verarbeitung</li>
<li>Recht auf Datenübertragbarkeit</li>
</ul>
<p>(2) Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren. Die zuständige Datenschutzbehörde für den Verantwortlichen ist der Diözesandatenschutzbeauftragte der norddeutschen Bistümer.</p>
<p><strong>Erhebung personenbezogener Daten bei Besuch unserer Website</strong></p>
<p>(1) Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist § 6 Abs. 1 lit. g KDG):</p>
<p>IP-Adresse<br />
Datum und Uhrzeit der Anfrage<br />
Zeitzonendifferenz zur Greenwich Mean Time (GMT)<br />
Inhalt der Anforderung (konkrete Seite)<br />
Zugriffsstatus/HTTP-Statuscode<br />
jeweils übertragene Datenmenge<br />
Website, von der die Anforderung kommt<br />
Browser<br />
Betriebssystem und dessen Oberfläche<br />
Sprache und Version der Browsersoftware&nbsp;</p>
<p>(2) Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.&nbsp;</p>
<p>Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden nicht an Dritte weitergegeben. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer Website vollumfänglich nutzen können. Wenn Sie nicht damit einverstanden sind, dass Ihre Seitenaufrufe in die Statistiken einbezogen werden, haben Sie mehrere Möglichkeiten:</p>
<p>Sie können HTTP-Cookies in Ihrem Browser löschen.<br />
Sie können in vielen modernen Browsern einstellen, den Seitenbetreibern zu signalisieren, sie nicht in derartige Statistiken einzubeziehen (sogenanntes „Do-not-track“). Wir halten uns an diese Vorgabe.</p>
<p>(3) Einsatz von weiteren Cookies&nbsp;</p>
<p>a) Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert werden:&nbsp;</p>
<p>Transiente Cookies (dazu b)<br />
Persistente Cookies (dazu c).</p>
<p>b) Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen.&nbsp;</p>
<p>c) Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.&nbsp;</p>
<p>Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z.B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle Funktionen dieser Website nutzen können.&nbsp;</p>   
    </div>

    );
    }
  }

  export default Datenschutz;