
  import React, { Component } from 'react';


//export default props => {
/**var element = document.getElementById("Wann");

element.scrollIntoView(); */

  class MenuItem extends Component {

    constructor(props) {
      super(props);
      this.state = {
        className:"Hidden"
      }
    }
  
    componentDidMount() {

    

      if(this.props.isOpen) this.setState({className:this.props.className});
        else this.setState({className:"Hidden"});

    }

    componentDidUpdate(prevProps) {
      if (this.props.isOpen !== prevProps.isOpen) {
        if(this.props.isOpen) setTimeout(()=>{this.setState({className:this.props.className})},100*this.props.index);
        else setTimeout(()=>{this.setState({className:"Hidden"})},400-(100*this.props.index));
      }
      else if(this.props.className !== prevProps.className){
        if(this.props.isOpen)this.setState({className:this.props.className});
      }
    }

    render(){
 
      

      return (
          <li className={this.state.className} key={this.props.index} onClick={this.props.mode==="Info"? ()=>this.props.setScreen(this.props.name):()=>this.props.setScreen(this.props.name,"RIGHT")}>
           {typeof(this.props.beforename)==="string"? <span>{this.props.beforename+this.props.name}</span>:this.props.name} 
          </li>
      );
    }
};

export default MenuItem;