import React, { Component } from 'react';
import Lottie from "lottie-web";

//import animationData_Herzen from './animationen/herzen.json'
import animationData_Ritter from './animationen/ritter.json';

import Overlay from "./Overlay";
import Marquee from "./Marquee";

import infobtn from './grafiken/info.svg'

  class ScreenRitter extends Component {



  constructor(props) {
    super(props);
    this.state = {
                  screen:"Ritter",
                  segment:0,
                  animation: null,
                  mounted:0,
                  isHashtagClicked:false,
                  HashtagClassname:"Btn Hashtag"

    };
  }
    
    componentDidMount() {
      console.log("Ritter Mount");
      let animation=Lottie.loadAnimation({
       container: document.getElementById("ritter"), // the dom element that will contain the animation
       renderer: "svg",
       loop: true,
       autoplay: false,
       animationData: animationData_Ritter, // the path to the animation json
       rendererSettings:{ preserveAspectRatio:'xMidYMid slice' }
     });
     animation.addEventListener("loaded_images",()=>this.animationloaded());
     animation.addEventListener("loopComplete",()=>this.animationloop());
     animation.addEventListener("segmentStart",()=>this.segmentstart());
     
     this.setState({animation:animation,mounted:1});
   }

   componentDidUpdate(prevProps) {

    if (this.props.show !== prevProps.show) {
      if (this.props.show){
        console.log("Kampf einblenden");
        this.state.animation.playSegments([0,1],true);
        this.state.animation.stop();
        this.setState({
          segment:0})
      }
      else{
        console.log("Kampf ausblenden");
        clearTimeout(this.timeout);
        this.timeout = null;
        this.setState({
                        segment:0,
                        isHashtagClicked:false,
                        HashtagClassname:"Btn Hashtag"
        });
      }
    }
   
    
    if (this.props.animateOut !== prevProps.animateOut) {
      if(this.props.animateOut){
        clearTimeout(this.timeout);
        this.timeout = null;
    }

 
  }
}
   componentWillUnmount() {
    
      Lottie.stop("ritter");

      this.state.animation.removeEventListener("DOMLoaded",()=>this.animationloaded());
      this.state.animation.removeEventListener("loopComplete",()=>this.animationloop());
      this.state.animation.removeEventListener("segmentStart",()=>this.segmentstart());
      this.state.animation.destroy();
   }
   animationloaded(){
     console.log("Animation geladen.");
     this.props.registerScreen("Kampf");
     
   }

   handleInfoBtnClick(){

    this.props.handleInfoBtnClick();
 

   }

   handleHashtagBtnClick(){
    this.state.animation.playSegments([[0,33],[33,90],[33,90],[33,90]], true);
    Lottie.play("ritter");
   
  
    this.setState({isHashtagClicked:true,
                  HashtagClassname:"Btn HashtagOff Hashtag"});
   }
 
   animationloop(){
     if(!this.props.show){
       this.state.animation.stop();
       clearTimeout(this.timeout);
        this.timeout = null;
     }
   }
 
   segmentstart(){
    if(this.props.show){ //safety
        let segment=this.state.segment;
        segment++;
        if(segment===2){ /** Maria 2.Segment = AnimationLayer einblenden */
        
          this.props.handleZeitsprung();
          this.setState({segment:segment});
        } else this.setState({segment:segment});
       
    }
   }

    
    
    render(){

      let {isAnimationOverlayInfo,isAnimationOverlayZeitsprung}=this.props;
      let {HashtagClassname}=this.state;

  return (

    <div className={this.props.screenClassname}>     
           {/**  <img className="CenterImage"src="/images/madonna.png" alt="Bild: Madonna"></img> 
          */}
          <div className={HashtagClassname}><div className="HashtagText" onClick={()=>this.handleHashtagBtnClick()}>#Kampf</div></div>
          
            {/** ########## OVERLAY SCREEN */}
            <Overlay animationStatus={isAnimationOverlayZeitsprung} profile={"Kampf"}> {/** AnimateIn and Out */}
                    <div className="Anzeige">
                      <Marquee move={this.props.show } text=" +++ Skandal: Letzter Drache getötet +++  "/>
                    </div>

                    <div className="AnzeigeText">
                    <p>Hildesheim, anno 1401: Ein seltsam gekleideter Mann tötet letzten Drachen. Tierschützer sind empört – die Polizei ermittelt. Der Mann sagt, er sei Ritter und dürfe das.</p>
                    </div>
                    
                    <div className="AnkuendigungText"><p>Mehr zur Drachentötung in der Ausstellung ZeitenWende 1400 vom 01.10.2019 bis zum 02.02.2020 im Dommuseum Hildesheim.</p></div>
                    <div className="InfoRow">
                      <div className="Btn InfoButtonText">mehr Informationen<br/>zum Ritter und Drachen</div>
                      <img className="InfoButton" src={infobtn} alt="Button: Info" onClick={()=>this.handleInfoBtnClick()}></img>
                    </div>      
              </Overlay>

              <Overlay animationStatus={isAnimationOverlayInfo} profile={"Info"}>
                  <div className="InfoText">
                  <h1>Hl. Georg aus Retabel der Trinitatiskapelle</h1>
                  Georg kämpfte als Soldat unter Kaiser Diokletian, doch dieser ließ ihn als Christ verfolgen und 303 n. Chr. enthaupten. Um 1400 verehrte man ihn daher als Märtyrer und Heiligen. Einer Sage zufolge soll er mit einem Drachen gekämpft haben und er überwältigte in diesem das Böse. Die Skulptur des Heiligen entstand um 1400 für einen Altaraufbau in der Kapelle des Trinitatis-Spitals in Hildesheim und konnte dort von dessen alten und kranken Bewohnern betrachtet werden.
                  </div>
              </Overlay>

          
    <div className="Animation" id="ritter"/> {/** Um Animation zu laden muss DIV schon bestehen. Eventuell noch in den Screen verschieben und Animation dort kontrollieren und später laden... */}

    </div>
 
    );
    }
  }

  export default ScreenRitter;