import React, { Component } from 'react';
import './Overlay.css';

  class Overlay extends Component {
    render(){
         let screenClassName="ScreenOverlay";
      if(this.props.fullscreen) screenClassName="ScreenOverlayFull";

        let OverlayClassname;
        if (this.props.animationStatus)OverlayClassname="OverlayOn "+screenClassName;
        else OverlayClassname="OverlayOff "+screenClassName;

        switch(this.props.profile){
            case "Madonna":OverlayClassname+=" Madonna";break;
            case "Kampf":OverlayClassname+=" Kampf";break;
            case "Ferne":OverlayClassname+=" Ferne";break;
            case "Messageboard":OverlayClassname+=" Messageboard";break;
            case "Info":OverlayClassname+=" Info";break;
            default: OverlayClassname+=" Default";
        }

  return (

    <div className={OverlayClassname}> {/** AnimateIn and Out */}
       
        <div className="AnimationOverlay">
            {this.props.children}
        </div>
         
      
    </div>

    );
    }
  }

  export default Overlay;