import React, { Component } from 'react';

import CountUp from 'react-countup';
import Overlay from "./Overlay";
import LogoDomWhite from './grafiken/Logo_Dommuseum_white.svg'
import { useCountUp } from 'react-countup';

const CompleteHook = () => {
  const { countUp, start, pauseResume, reset, update } = useCountUp({
    start: 0,
    end: 88,
    delay: 1,
    duration: 5,
    onReset: () => console.log('Resetted!'),
    onUpdate: () => console.log('Updated!'),
    onPauseResume: () => console.log('Paused or resumed!'),
    onStart: ({ pauseResume }) => console.log(pauseResume),
    onEnd: ({ pauseResume }) => console.log(pauseResume),
  });

  //setTimeout(()=>reset(), 8000);
  return (

    <span>
      {countUp}
    </span>
  );
};

class ScreenInfo extends Component {



  constructor(props) {
    super(props);
    this.state = {
      isAnimationOverlayCount: true,
      isAnimationOverlayInfo: false,
      isAnimationOverlayInfob: false,
      isInfoBottomReached: false,
      infoDiv: null,
      counterTextclassName: "CounterText"
    };
  }

  componentDidMount() {
    this.props.scrollScreen((dir, deltaY,velocity) => this.handleScroll(dir, deltaY,velocity));
    const objDiv = document.getElementById('InfoDiv');
    // objDiv.scrollTop = objDiv.scrollHeight;
    //objDiv.scrollTop = 35;
    /* 
     this.timeout=  setTimeout(()=>this.handleInfoIn(), 5000);
    
*/




    this.setState({
      infoDiv: objDiv,
      isAnimationOverlayInfo: true
    });
  }

  handleInfoIn() {
    this.timeout = setTimeout(() => this.setState({ isAnimationOverlayCount: false }), 250);
    this.setState({
      isAnimationOverlayInfo: true
    });
  }

  componentDidUpdate(prevProps) {

    if (this.props.show !== prevProps.show) {

      if (!this.props.show) {
        clearTimeout(this.timeout);
        this.state.infoDiv.scrollTop = 0;
        this.state.infoDiv.style.transitionDuration = "1ms";
        this.setState({
          isAnimationOverlayCount: true,
          isAnimationOverlayInfo: false,
          isAnimationOverlayInfob: false,
          isInfoBottomReached: false,
          counterTextclassName: "CounterText"
        })

      }
      else if (this.props.show) {
        console.log("Screen Info einblenden");
        this.state.infoDiv.style.transitionDuration = "15000ms";
        this.timeout = setTimeout(() => this.setState({ counterTextclassName: "fade CounterText" }), 500);
        //this.timeout=  setTimeout(()=>this.handleInfoIn(), 5000);
      }
    }


  }

  componentWillUnmount() {


  }

  handleScroll(dir, deltaY,velocity) {

    this.state.infoDiv.scrollTop += deltaY * velocity*0.3;//0.03;
    /*
    if(dir==="Up")this.state.infoDiv.scrollTop+=deltaY*0.03;
    else this.state.infoDiv.scrollTop+=(deltaY*0.03)*-1;
 */
    /*
    if(dir==="UP"){  
                       
                         if(this.state.infoDiv.scrollTop<this.state.infoDiv.scrollHeight - this.state.infoDiv.clientHeight)
                         {
                           var i = 0;
                           var start=this.state.infoDiv.scrollTop;
                           var goal = deltaY;//this.state.infoDiv.clientHeight-250; 
                           var length = 20; 
                          
                           this.scrollsmooth(i,start,goal,length,false);
                     }
                 
 
                   }
    else if(dir==="DOWN"){ 
                     if(this.state.infoDiv.scrollTop>0){
                           var i = 0;
                           var start=this.state.infoDiv.scrollTop;
                           var goal = deltaY*-1;//(this.state.infoDiv.clientHeight-250); 
                           var length = 20; 
                           this.scrollsmooth(i,start,goal,length,true);
                        }
                       }*/
  }

  /*
  easeOutSine(t, b, c, d,dir) {
   if(dir){
           return b-(c * Math.sin(t/d * (Math.PI/2)));
         }
   else return c * Math.sin(t/d * (Math.PI/2)) + b;
 }
 
   
 
 scrollsmooth(i,start,goal,length,dir) {
     if ( i <= length ) { 
         let o = this.easeOutSine( i, start , goal, length,dir);
         i++;
         this.state.infoDiv.scrollTop = o;
         window.requestAnimationFrame( ()=>this.scrollsmooth(i,start,goal,length,dir) ); //Check BrowserSupport RequestAnimationFrame
     } else {
         // code to be executed when animation finishes
         let {isInfoBottomReached}=this.state;
         this.setState({isInfoBottomReached:!isInfoBottomReached});
     }
 };*/





  render() {

    let { isAnimationOverlayZeitsprung } = this.props;
    let { isAnimationOverlayCount, isAnimationOverlayInfo, isAnimationOverlayInfob } = this.state;

    let screenClassname;
    if (!this.props.show) {
      screenClassname = "ScreenHidden Screen";
    }
    else if (this.props.animateOut) {
      if (this.props.direction == "LEFT") screenClassname = "ScreenOutLeft Screen";
      else screenClassname = "ScreenOutRight Screen";
    } else if (this.props.animateIn) {
      if (this.props.direction == "LEFT") screenClassname = "ScreenInRight Screen";
      else screenClassname = "ScreenInLeft Screen";
    }
    else screenClassname = "Screen";
    //const theDiv = document.querySelector('#AusstellungInfo');
    //theDiv.scrollTop = Math.pow(10, 10);



    return (

      <div className={this.props.screenClassname}>

        <img className="CenterImage" src="/images/madonna.png" alt="Bild: Madonna" />
        {/** <Overlay animationStatus={isAnimationOverlayCount} profile={"Info"}>*/}

        <div className={this.state.counterTextclassName} id="InfoDiv">
          <span className="ScrollToElement" id="InfoDiv_start" />
          {this.props.show ?
            <span className="CountNumber" >
              <CountUp
                start={0}
                end={1}
                delay={0.5}
                duration={2.5}
                separator=""
                onEnd={() => console.log('Ended! 👏')}
                onStart={() => console.log('Started! 💨')}
              />
            </span>
            : null}
          &nbsp;Ausstellung
                    <br />

          {this.props.show ?
            <span className="CountNumber">
              <CountUp
                start={0}
                end={2}
                delay={0.75}
                duration={3}
                separator=""
                onEnd={() => console.log('Ended! 👏')}
                onStart={() => console.log('Started! 💨')}
              />
            </span>
            : null}
          &nbsp;Orte
                    <br />

          {this.props.show ?
            <span className="CountNumber">
              <CountUp
                start={0}
                end={88}
                delay={1}
                duration={5}
                separator=""
                onEnd={() => console.log('Ended! 👏')}
                onStart={() => console.log('Started! 💨')}
                countUpRef={(countUp) => { this.countExponate = countUp; }}
              />
            </span>
            : null}
          &nbsp;Exponate

                    {/*this.props.show? <CompleteHook reset={this.props.show}/>:null*/}
          <br /><br />



          <div className="AusstellungInfo" id="InfoDiv_AusstellungInfoDiv">
            {this.props.device === "desktop" ?
              <h1>Zeitenwende 1400 - Welt im Wandel</h1>
              : <h1>Zeitenwende 1400<br /> – <br />Welt im Wandel</h1>}
            <br />
            <p>
              Klimatische Veränderungen und Epidemien stehen neben technischer Innovation und einer zukunftsweisenden Fokussierung auf den Menschen als Individuum. Im städtischen Raum entsteht eine dynamische, von Veränderungen geprägte Gesellschaft.
                    </p>
            <p>
              In Hildesheim werden diese Entwicklungen an ganz unterschiedlichen Stellen greifbar. Große Bauprojekte werden realisiert. Das städtische Sozialwesen, besonders die Hospitäler, werden erweitert und sind gleichzeitig religiöse Orte mit herausragenden k&uuml;nstlerischen Ausstattungen.
                    </p>
            <p>
              Neue intellektuelle Ideen kommen in die Stadt. Die „Devotio moderna“ versteht Glauben als direkten Kommunikationsakt zwischen Mensch und Gott. Das Bild gewinnt an Bedeutung und soll die Menschen emotional erreichen Austausch und Netzwerke bestimmen die Lebenswirklichkeit der Welt um 1400, deren vielschichtige Bez&uuml;ge zur Gegenwart in der Ausstellung anschaulich werden.
                    </p>
            <br /><br />
          </div>

          <div className="AusstellungInfoWann" >
            <span className="ScrollToElement" id="InfoDiv_Wann" />

            <ul>
              <li>  <h1> Wann?</h1> </li>
              <li> <h2>01.10.2019 bis 02.02.2020</h2></li>
            </ul>
            <ul>
              <li> <h1>Wo?</h1> </li>
              <li> <img className="LogoDomWann" src={LogoDomWhite} alt="Logo: Dommuseum" ></img>  </li>
              <li> <h2>
                Domhof, 31334 Hildesheim
                www.dommuseum-hildesheim.de
                                   </h2>
              </li>
            </ul>
            <br /><br />
          </div>

          <div className="AussstellungInfoDetails">

            <h3>Öffnungszeiten</h3>
            <p>
              Dienstag bis Sonntag, feiertags 11 – 17 Uhr<br />
            </p>
            <h3> Eintritt Dommuseum</h3>
            <p>
              6 Euro / ermäßigt 4 Euro pro Person (Inhaber von Sozial- oder Behindertenausweis,
                        Gruppen ab 10 Personen)<br />
              Eintrittspauschale – 25€ f&uuml;r eine Gruppe mit max. 25 Teilnehmern. Die Eintrittspauschale
                        gilt nur in Verbindung mit einer F&uuml;hrung (Dom oder Museum).<br />
              Freier Eintritt bis 25 Jahre. Freier Eintritt f&uuml;r alle am ersten Dienstag des Monats.
                        </p>
            <span className="ScrollToElement" id="InfoDiv_Fuehrungen" />
            <h3 >F&uuml;hrungen/Infos/Kontakt:</h3>
            <p>
              Museumsf&uuml;hrungen stellen den Teilnehmern die Sonderausstellung „Zeitenwende
              1400“ vor und erschließen lebendig und fundiert die verschiedenen Themenbereiche
                        und Kunstwerke. <br />(Dauer ca. 60 Minuten)
                        </p>
            <h3>Kurzf&uuml;hrung „Zeitenwende 1400“</h3>
            <p>
              Kurze Einf&uuml;hrung in die Sonderausstellung und Vorstellung eines ausgewählten
                        Themenbereichs. <br />(Dauer ca. 30 Minuten)
                        </p>
            <p>
              <br />
              Domf&uuml;hrungen geben einen &uuml;berblick &uuml;ber das eindrucksvolle Gebäude, seine Geschichte und die hochkarätigen Ausstattungsst&uuml;cke, Bernwardsäule oder Heziloleuchter. Ein Besuch beim sagenumwobenen 1000-jährigen Rosenstock darf dabei nat&uuml;rlich nicht fehlen. Mit dem Blick auf das gotische Nordparadies oder die Tintenfassmadonna gelingt hier der thematische Br&uuml;ckenschlag zur Sonderausstellung „ZeitenWende 1400“. <br />(Dauer ca. 60 Minuten, Gew&uuml;nschter Themenschwerpunkt in Absprache)
                        </p>
            <p>
              <br />
              Dom- und Museumsf&uuml;hrung können einzeln oder in Kombination gebucht werden.
              Ebenso ist jeweils die eigenständige Besichtigung möglich. F&uuml;r das Dommuseum
              ist in diesem Fall lediglich der Eintritt zu entrichten. Der Besuch des
              Doms ist kostenfrei.
                        </p>

            <h3>F&uuml;hrungsgeb&uuml;hren</h3>
            <p>
              Pro Gruppe bis 25 Teilnehmer 90 Euro / ermäßigt 70 Euro (Sch&uuml;ler, Studenten,
                        bei gleichzeitiger Buchung von Dom und Museumsf&uuml;hrung)<br />
              Kurzf&uuml;hrung Zeitenwende – 40 Euro zzgl. 4 Euro Eintritt pro Person
                        </p>

            <h3>Information und Buchung</h3>
            <h4>
              Dom-Information<br />
              + 49 (0) 5121-307770<br /><br />
              dom-information@bistum-hildesheim.de<br />
              www.dom-hildesheim.de<br />
              www.dommuseum-hildesheim.de
                        </h4>
            <br /><br /><br />
      </div>
      <div className="FoerdererLogos">
      
            <h4>Kooperation</h4>
            <div className="FoerdererContainer">
            <div className="FoerdererItem">     <img src="/images/LMH_Logo.png"></img>  </div>
            </div>
            <h4>F&ouml;rderer</h4>
            <span className="ScrollToElement" id="InfoDiv_Foerderer" />
            <div className="FoerdererContainer">
            <div className="FoerdererItem">     <img src="/images/Kulturstiftung.png"></img>  </div>
            <div className="FoerdererItem">     <img src="/images/MWK-Wappen-CMYK.png"></img>  </div>
            <div className="FoerdererItem">     <img src="/images/EvSK-Logo.gif"></img>  </div>
            <div className="FoerdererItem">     <img src="/images/nis_raum_4c.png"></img>  </div>
            <div className="FoerdererItem">     <img src="/images/SparkasseGosHilPei.png"></img>  </div>
            <div className="FoerdererItem">     <img src="/images/Logo-VGH-Stiftung.png"></img>  </div>
            <div className="FoerdererItem">     <img src="/images/Dombauverein_Logo.png"></img>  </div>
            <div className="FoerdererItem">     <img src="/images/Hi_Landschaft.png"></img>  </div>
            <div className="FoerdererItem">     <img src="/images/Logo-Landschaftsverband.gif"></img>  </div>
          </div>
          <br /><br /><br /><br />
          </div>
          </div>
      </div>
    );
  }
}
export default ScreenInfo;