import React, { Component } from 'react';
import Lottie from "lottie-web";
import animationData_Herzen from './animationen/herzen.json'

import Overlay from "./Overlay";
import Marquee from "./Marquee";

import infobtn from './grafiken/info.svg'

  class ScreenLiebe extends Component {



  constructor(props) {
    super(props);
    this.state = {
                  screen:"Liebe",
                  segment:0,
                  animation: null,
                  mounted:0,
                  isHashtagClicked:false,
                  HashtagClassname:"Btn Hashtag"
    };
  }
    
    componentDidMount() {
      console.log("Liebe Mount");
      let animation=Lottie.loadAnimation({
       container: document.getElementById("herzen"), // the dom element that will contain the animation
       renderer: "svg",
       loop: true,
       autoplay: false,
       animationData: animationData_Herzen, // the path to the animation json
       rendererSettings:{ preserveAspectRatio:'xMidYMid slice' }
     });
    // animation.addEventListener("DOMLoaded",()=>this.animationloaded());
    animation.addEventListener("DOMLoaded",()=>this.animationloaded());
     animation.addEventListener("loopComplete",()=>this.animationloop());
     animation.addEventListener("segmentStart",()=>this.segmentstart());
     
     this.setState({animation:animation,mounted:1});
   }

   componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      if (this.props.show){
        console.log("Liebe EINBLENDEN");
        this.state.animation.playSegments([0,1],true);
        this.state.animation.stop();
        this.setState({segment:0})
      }
      else{
        console.log("Liebe AUSBLENDEN");
        clearTimeout(this.timeout);
        this.timeout = null;
        this.setState({segment:0,
                        isHashtagClicked:false,
                        HashtagClassname:"Btn Hashtag"
        });
      }
    }

      if (this.props.isAnimationOverlayZeitsprung !== prevProps.isAnimationOverlayZeitsprung) {
        this.setState({isAnimationOverlayZeitsprung:this.props.isAnimationOverlayZeitsprung});
      }

      if (this.props.isAnimationOverlayInfo !== prevProps.isAnimationOverlayInfo) {
        this.setState({isAnimationOverlayInfo:this.props.isAnimationOverlayInfo});
      }

      if (this.props.animateOut !== prevProps.animateOut) {
        if(this.props.animateOut){
          clearTimeout(this.timeout);
          this.timeout = null;
          this.setState({
            isAnimationOverlayInfo:false,
            isAnimationOverlayZeitsprung:false})
        }
      }
    }

   componentWillUnmount() {
    this.state.animation.stop();
    this.state.animation.removeEventListener("DOMLoaded",()=>this.animationloaded());
    this.state.animation.removeEventListener("loopComplete",()=>this.animationloop());
    this.state.animation.removeEventListener("segmentStart",()=>this.segmentstart());
    this.state.animation.destroy();
    
 }
 
   animationloaded(){
     console.log("Animation geladen.");
     this.props.registerScreen("Liebe");
     
   }
   handleInfoBtnClick(){
    this.props.handleInfoBtnClick();
    this.setState({
      isAnimationOverlayInfo:true,
      isAnimationOverlayZeitsprung:false
    })
   }

   handleHashtagBtnClick(){
    this.state.animation.playSegments([[0,100],[100,231],[61,231]], true);
    Lottie.play("herzen");
   
    this.setState({isHashtagClicked:true,
                  HashtagClassname:"Btn HashtagOff Hashtag"});
   }
 
   animationloop(){
     console.log("animationloop.");
     if(!this.props.show){
       this.state.animation.stop();
      clearTimeout(this.timeout);
      this.timeout = null;
    }
   }
 
   segmentstart(){
    if(this.props.show){ //safety
        let segment=this.state.segment;
        segment++;
        if(segment===2){ /** Maria 2.Segment = AnimationLayer einblenden */
          // let isAnimationOverlayMaria=!this.state.isAnimationOverlayMaria;
          this.props.handleZeitsprung();
          this.setState({segment:segment,isAnimationOverlayZeitsprung:true});
        } else this.setState({segment:segment});
    }
   }

    
    
    render(){

      let {isAnimationOverlayInfo,isAnimationOverlayZeitsprung}=this.props;
      let {HashtagClassname}=this.state;

  return (

    <div className={this.props.screenClassname}>     
            <img className="CenterImage"src="/images/madonna.png" alt="Bild: Madonna"></img> 
          
          <div className={HashtagClassname}><div className="HashtagText" onClick={()=>this.handleHashtagBtnClick()}>#Liebe</div></div>
          
            {/** ########## OVERLAY SCREEN */}
            
            
            <Overlay animationStatus={isAnimationOverlayZeitsprung} profile={"Madonna"}> {/** AnimateIn and Out */}
                    <div className="Anzeige">
                      <Marquee move={this.props.show } text=" +++ Krippenplatz gesucht +++  "/>
                    </div>

                    <div className="AnzeigeText">
                    <p>Junge Mutter aus Galiläa, 16, politisch verfolgt, sucht sicheren Krippenplatz für ihren göttlichen Sohn. Angebote unter #Liebe.</p>
                    </div>
                    
                    <div className="AnkuendigungText"><p>Mehr zur Jungfrau Maria und ihrem ungelösten Geheimnis in der Ausstellung ZeitenWende 1400 vom 01.10.2019 bis zum 02.02.2020 im Dommuseum Hildesheim.</p></div>
                    <div className="InfoRow">
                      <div className="Btn InfoButtonText">mehr Informationen<br/>zur Madonna</div>
                      <img className="InfoButton" src={infobtn} alt="Button: Info" onClick={()=>this.handleInfoBtnClick()}></img>
                    </div>      
              </Overlay>

              
         
              <Overlay animationStatus={isAnimationOverlayInfo} profile={"Info"}>
                  <div className="InfoText">
                  <h1>Statuette der Gottesmutter</h1>
                  Die nur 25 cm hohe Figur der Maria mit dem Kind lädt den Betrachter zur Beschäftigung mit der Muttergottes ein. Aus verschiedenen Positionen kann er sich dem Bildwerk nähern und sich geistig in das Bildthema hineinversenken. Aus dem Hildesheimer Kartäuserkloster soll die Madonna stammen. Verschiedene Formen der Andacht waren im Tagesablauf des strengen Ordens zentral. Die Kartause wurde von Bischof Gerhard vom Berge (1365–1398) außerhalb der Stadtmauern gegründet. Dieser wird die Figur, die wohl im zweiten Viertel des 14. Jahrhunderts entstand, seiner Gründung gestiftet haben.
                  </div>
              </Overlay>

          
    <div className="Animation" id="herzen"/> {/** Um Animation zu laden muss DIV schon bestehen. Eventuell noch in den Screen verschieben und Animation dort kontrollieren und später laden... */}

    </div>
 
    );
    }
  }

  export default ScreenLiebe;