import React, { Component,useReducer } from 'react';
import SideBar from "./Sidebar";

import ScreenLiebe from "./ScreenLiebe";
import ScreenRitter from "./ScreenRitter";
import ScreenWeltkarte from "./ScreenWeltkarte";
import ScreenMessage from './ScreenMessage';
import ScreenInfo from './ScreenInfo';
import ScreenProgramm from './ScreenProgramm';
import ScreenLoading from "./ScreenLoading";
import MenuItem from "./MenuItem";
import Impressum from './Impressum';
import Datenschutz from './Datenschutz';

import Swipe from "./Swipe";
import SwipeableViews from 'react-swipeable-views';

import Lottie from "lottie-web";
import animationData_Audio from './animationen/audio.json';
import animationData_Share from './animationen/share.json'

import burgerbtn from './grafiken/burgerbtn.svg'
import audiobtn from './grafiken/audio.svg'
import sharebtn from './grafiken/share.svg'
import infobtn from './grafiken/info.svg'
import downbtn from './grafiken/down.svg'
import arrow from './grafiken/arrow.svg'
import upbtn from './grafiken/up.svg'
import logodommuseum from './grafiken/Logo_Dommuseu.svg'
import LogoZeitenwende from './grafiken/zeitenwende1400.svg'
import soundfileA from './audio/Machaut_Mix2.mp3'
import { runInThisContext } from 'vm';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  PocketShareButton,
  InstapaperShareButton,
  EmailShareButton
} from 'react-share';

import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  MailruIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  EmailIcon
} from 'react-share';



class Dommuseum extends Component {

  constructor(props) {
    super(props);
    this.state = {
                  screen:null,

                  isDataReady:true,
                  
                  allowSwipe:true,
                  socialMedia:[ {Media:FacebookShareButton,Icon:FacebookIcon},
                                {Media:LinkedinShareButton,Icon:LinkedinIcon},
                                {Media:TwitterShareButton,Icon:TwitterIcon},
                                {Media:TelegramShareButton,Icon:TelegramIcon},
                                {Media:WhatsappShareButton,Icon:WhatsappIcon},
                                //{media:PinterestShareButton,Icon:PinterestIcon},//Media Picture needed
                                {Media:VKShareButton,Icon:VKIcon},
                                {Media:OKShareButton,Icon:OKIcon},
                                {Media:RedditShareButton,Icon:RedditIcon},
                                {Media:TumblrShareButton,Icon:TumblrIcon},
                                {Media:LivejournalShareButton,Icon:LivejournalIcon},
                                {Media:MailruShareButton,Icon:MailruIcon},
                                {Media:ViberShareButton,Icon:ViberIcon},
                                {Media:WorkplaceShareButton,Icon:WorkplaceIcon},
                                {Media:LineShareButton,Icon:LineIcon},
                                {Media:PocketShareButton,Icon:PocketIcon},
                                {Media:InstapaperShareButton,Icon:InstapaperIcon},
                                {Media:EmailShareButton,Icon:EmailIcon}
                                ],
                 // screensinit:[],
                  screens:["Kampf","Liebe","Welt","Message","Info","Programm"],
                  screenstates:{Kampf:{name:"Kampf",screenClassname:"ScreenHidden Screen",animateIn:false,animateOut:false,isAnimationOverlayZeitsprung:false,isAnimationOverlayInfo:false,show:false},
                                Liebe:{name:"Liebe",screenClassname:"ScreenHidden Screen",animateIn:false,animateOut:false,isAnimationOverlayZeitsprung:false,isAnimationOverlayInfo:false,show:false},
                                Welt:{name:"Welt",screenClassname:"ScreenHidden Screen",animateIn:false,animateOut:false,isAnimationOverlayZeitsprung:false,isAnimationOverlayInfo:false,show:false},
                                Message:{name:"Message",screenClassname:"ScreenHidden Screen",animateIn:false,animateOut:false,isAnimationOverlayZeitsprung:false,isAnimationOverlayInfo:false,show:false},
                                Info:{name:"Info",screenClassname:"ScreenHidden Screen",animateIn:false,animateOut:false,isAnimationOverlayZeitsprung:false,isAnimationOverlayInfo:false,show:false,section:null},
                                Programm:{name:"Programm",screenClassname:"ScreenHidden Screen",animateIn:false,animateOut:false,isAnimationOverlayZeitsprung:false,isAnimationOverlayInfo:false,show:false,section:null}
                              },
                  segment:0,
                  animation: null,
                  mobileMenuIsOpen:false,
                  
                  isHashtagClicked:false,
                  downBtnClass: "HideButton DownButton",
                  upBtnClass: "HideButton UpButton",
                  swipeDir:null,
                  isAudioplay:false,
                  audio:null,
                  isTrailerOpen:true,
                  isInfoOpen:false,
                  isProgrammOpen:false,
                  isShareOverlay:false,
                  animationAudio:null,
                  animationShare:null,
                  isImpressumOpen:false,
                  isDatenschutzOpen:false
                  
    };
    //<audio src="./audio/Machaut_Mix2.mp3" preload="auto"></audio>
    
  }

  componentDidMount() {
    var audio = new Audio(soundfileA);
    audio.loop=true;
    var screenname;

    let animationAudio=Lottie.loadAnimation({
      container: document.getElementById("audiobtn"), // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: false,
      animationData: animationData_Audio, // the path to the animation json
      rendererSettings:{ preserveAspectRatio:'xMidYMid slice' }
    });

    let animationShare=Lottie.loadAnimation({
      container: document.getElementById("sharebtn"), // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: false,
      animationData: animationData_Share, // the path to the animation json
      rendererSettings:{ preserveAspectRatio:'xMidYMid slice' }
    });

   // animation.addEventListener("DOMLoaded",()=>this.animationloaded());
   animationAudio.addEventListener("DOMLoaded",()=>this.animationloaded("audio"));
   animationAudio.addEventListener("loopComplete",()=>this.animationloop("audio"));
   animationAudio.addEventListener("segmentStart",()=>this.segmentstart("audio"));

   animationShare.addEventListener("DOMLoaded",()=>this.animationloaded("share"));
   animationShare.addEventListener("loopComplete",()=>this.animationloop("share"));
   animationShare.addEventListener("segmentStart",()=>this.segmentstart("share"));

   const el = document.querySelector('div');
   el.addEventListener('wheel', (el)=>this.wheelScroll(el));
    
   var downBtnClass="Btn HideButton DownButton";
   var upBtnClass="Btn HideButton UpButton";

        switch(this.props.hashScreen){ //Statt Zahlen String und switch weg?!
          case 0: screenname="Kampf";break;
          case 1: screenname="Liebe";break;
          case 2: screenname="Welt";break;
          case 3: screenname="Message";break;
          case 4: screenname="Info";
                    downBtnClass="Btn ScrollDownButton";
                    upBtnClass="Btn ScrollUpButton";
                    break;
          case 5: screenname="Programm";
                    downBtnClass="Btn ScrollDownButton";
                    upBtnClass="Btn ScrollUpButton";
                    break;
          default: //Kein Screen vorhanden, Standard Startscreen: Kampf 
                    screenname=null;
                   
                    this.setState(prevState => ({screen:0, audio:audio, animationAudio:animationAudio,animationShare:animationShare,
                      isImpressumOpen:false,
                      isDatenschutzOpen:false,
                    screenstates:{...prevState.screenstates,Kampf:{...prevState.screenstates.Kampf,show:true,screenClassname:"Screen"}}}));
                    this.setBrowserLocation("Kampf");
                    break;
        }

    if(screenname){
        this.setState(prevState => ({screen:this.props.hashScreen, audio:audio, animationAudio:animationAudio,animationShare:animationShare,downBtnClass:downBtnClass,upBtnClass:upBtnClass,isImpressumOpen:false,
          isDatenschutzOpen:false,
                                     screenstates:{...prevState.screenstates,[screenname]:{...prevState.screenstates[screenname],show:true,screenClassname:"Screen"}}
                                    }));
         this.setBrowserLocation(this.state.screens[this.props.hashScreen]);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.hashScreen !== prevProps.hashScreen) {
      this.setState({screen:this.props.hashScreen});
    }
  }
   
  wheelScroll(element){

    if(this.state.screenstates.Info.show)
    {
        let objDiv = document.getElementById('InfoDiv');
        objDiv.scrollTop+=element.deltaY;
    }

    if(this.state.screenstates.Programm.show)
    {
        let objDiv = document.getElementById('ProgrammDiv');
        objDiv.scrollTop+=element.deltaY;
    }

  }
  animationloaded(animationName){console.log("ANIMATION animationloaded NAME:"+animationName);}
 animationloop(animationName){console.log("ANIMATION animationloop NAME:"+animationName);


}
segmentstart(animationName){
                                        console.log("ANIMATION segmentstart NAME:"+animationName);

                                      //  this.state.animationShare.playSegments([0,50,50,100], true);
                            }

  handleScreenLoading(screenloaded){
  

    //Feedback wenn aktueller Screen geladen ist...
    /*
      let {screen}=this.state;
    switch(screenloaded){ 
      case "Kampf": if(screen===0)this.setState({isDataReady:true}); break;
      case "Liebe": if(screen===1)this.setState({isDataReady:true}); break;
      case "Ferne": if(screen===2)this.setState({isDataReady:true}); break;
      case "Message": if(screen===3)this.setState({isDataReady:true}); break;
      case "Info": if(screen===4)this.setState({isDataReady:true}); break;
    }
  */
     // this.setState({screensinit:[...this.state.screensinit,screenloaded]});
  }

  getScreen(screen,mode){
    if(mode==="string"){ //return string
      if(typeof(screen)==="number")
          switch(screen){ //Statt Zahlen String und switch weg?!
            case 0: screen="Kampf";break;
            case 1: screen="Liebe";break;
            case 2: screen="Welt";break;
            case 3: screen="Message";break;
            case 4: screen="Info";break;
            case 5: screen="Programm";break;
          }
    }
    else if (mode==="number"){ //return number
      if(typeof(screen)==="string") {
        switch(screen){ 
          case "Kampf": screen=0;break;
          case "Liebe": screen=1;break;
          case "Welt": screen=2;break;
          case "Message": screen=3;break;
          case "Info": screen=4;break;
          case "Programm": screen=5;break;
        }
      }
    }
        
    return screen;
  }

  setScreen(newscreen,dir,section){ //Time Delays und Async für Animationen einrichten.
     

        var {screen,screens,allowSwipe,isInfoOpen,isTrailerOpen}=this.state; //oldScreen
        var screenname=this.getScreen(newscreen,"string");
        var screennumber=this.getScreen(newscreen,"number");

        if(screennumber!==screen) //Nur wenn neuer Screen
        {
            var {screenstates}=this.state;
            var downBtnClass="Btn HideButton DownButton";
            var upBtnClass="Btn HideButton UpButton";

              if(screennumber>this.state.screens.length-1) screennumber=0; //safety
              else if (screennumber<0)screennumber=this.state.screens.length-1;
            

            Object.keys(screenstates).map(e => {
              if (screens[screennumber]===e){ //Den neuen Screen einblenden
                  screenstates[e].show=true; 
                  screenstates[e].animateIn=true;
                  screenstates[e].isAnimationOverlayZeitsprung=false;
                  screenstates[e].isAnimationOverlayInfo=false;
                  if(dir==="LEFT")screenstates[e].screenClassname="ScreenInRight Screen";
                  else screenstates[e].screenClassname="ScreenInLeft Screen";

                  if(e==="Info" || e==="Programm") {
                    if(section!==null && section!==undefined) screenstates[e].section=section;
                    else screenstates[e].section=0;

                    downBtnClass="Btn ScrollDownButton";
                    upBtnClass="Btn ScrollUpButton";
                  }
                }
              else if (screenstates[e].show) //Den alten Screen ausblenden
                {
                  screenstates[e].isAnimationOverlayZeitsprung=false;
                  screenstates[e].isAnimationOverlayInfo=false;
                  screenstates[e].animateIn=false;
                  screenstates[e].animateOut=true;
                  if(dir==="LEFT")screenstates[e].screenClassname="ScreenOutLeft Screen";
                  else screenstates[e].screenClassname="ScreenOutRight Screen";

                  this.timeoutSwipe=setTimeout(()=>{this.setState(prevState => ({ allowSwipe:true,
                                                                            screenstates:{...prevState.screenstates,
                                                                                            [e]:{...prevState.screenstates[e],
                                                                                                  show:false,
                                                                                                  screenClassname:"ScreenHidden Screen",
                                                                                                  isAnimationOverlayZeitsprung:false,
                                                                                                  isAnimationOverlayInfo:false,
                                                                                                  animateOut:false}}}));}
                                                                                                  , 1000);    
                  }
              
            });

            this.setBrowserLocation(screenname);
            //Set screen, reset overlays and buttons
            this.setState({screen:screennumber,
              isImpressumOpen:false,
                      isDatenschutzOpen:false,
                          screenstates:screenstates, 
                          swipeDir:dir,
                          allowSwipe:false,
                          isUpButton:false,
                          downBtnClass:downBtnClass,
                          upBtnClass:upBtnClass,
                          isInfoOpen:isInfoOpen,
                        isTrailerOpen:isTrailerOpen,
                        mobileMenuIsOpen:false});
        } 
      
}

  handleOverlay(dir,deltaY,velocity,swipping){
    var {screenstates}=this.state;
    Object.keys(screenstates).map(e => {
      if(screenstates[e].show){
        if(e==="Info")this.scrollScreenInfo(dir,deltaY,velocity);
        if(e==="Programm")this.scrollScreenProgramm(dir,deltaY,velocity);

        if(screenstates[e].isAnimationOverlayZeitsprung && !swipping){
          this.setState(prevState => ({downBtnClass:"Btn ShowButton DownButton", screenstates:{...prevState.screenstates,[e]:{...prevState.screenstates[e],isAnimationOverlayZeitsprung:false,isAnimationOverlayInfo:true}}}));
        }
        else if(screenstates[e].isAnimationOverlayInfo && !swipping){
          this.setState(prevState => ({isUpButton:true, upBtnClass:"Btn ShowButton UpButton", downBtnClass:"Btn HideButton DownButton", screenstates:{...prevState.screenstates,[e]:{...prevState.screenstates[e],isAnimationOverlayZeitsprung:false,isAnimationOverlayInfo:false}}}));
        }
        else if(this.state.isUpButton && !swipping){
          if(e==="Welt")this.restartAnimation();
          this.setState(prevState => ({isUpButton:false, upBtnClass:"Btn HideButton UpButton",  screenstates:{...prevState.screenstates,[e]:{...prevState.screenstates[e],isAnimationOverlayZeitsprung:true,isAnimationOverlayInfo:false}}}));
        }
      }
    });
  } 

  handleonMouseEnter(event){
    //this.state.animationAudio.play();
    switch(event){
      case "audio": 
                    this.state.animationAudio.loop=true;
                    if(this.state.isAudioplay)this.state.animationAudio.playSegments([60,83], true);
                    else this.state.animationAudio.playSegments([1,25], true);
                    break;
      case "share": 
                    if(!this.state.isShareOverlay){ 
                      this.state.animationShare.setSpeed(1);
                      this.state.animationShare.loop=true;
                      this.state.animationShare.playSegments([[0,50],[50,100]], true);
                    }
                     break;
    }
    
    
  }

  handleonMouseLeave(event){
    
    switch(event){
      case "audio": 
                      this.state.animationAudio.loop=false;
                    if(this.state.isAudioplay) {
                      this.state.animationAudio.playSegments([53,55], true);
                     // this.state.animationAudio.goToAndStop(55,true);
                      
                    }
                    else{  this.state.animationAudio.playSegments([80,82], true);
                     // this.state.animationAudio.goToAndStop(82,true);
                    }
                    break;
      case "share": 
                  if(!this.state.isShareOverlay){
                    this.state.animationShare.loop=false;
                    this.state.animationShare.setSpeed(10);
                      this.state.animationShare.playSegments([100,110], false);
                    //this.state.animationShare.playSegments([0,1], true);
                  }
                    break;
    }
 
  }

  handleBurgerMenuClick(){
    let menuState=!this.state.mobileMenuIsOpen;
    this.setState({mobileMenuIsOpen:menuState});
  }

  handleAudioBtnClick(){
        if(this.state.isAudioplay){
          this.state.animationAudio.loop=false;
          this.state.animationAudio.playSegments([60,82], true);
          this.setState({isAudioplay:false},()=>{this.state.audio.pause();});
        }else {
          this.state.animationAudio.loop=false;
          this.state.animationAudio.playSegments([33,55], true);
          
          
          var playPromise = this.state.audio.play();

          if (playPromise !== undefined) {
            playPromise.then(_ => {
              this.setState({isAudioplay:true});
              
            })
            .catch(error => {
              console.log("Playback rejected");
              console.log(error);
            });
          }
        }
  }

  handleShareBtnClick(){

      this.setState({isShareOverlay:!this.state.isShareOverlay},()=>{
      if(this.props.device!=="desktop")
      {
        if(this.state.isShareOverlay){
            this.state.animationShare.setSpeed(1);
            this.state.animationShare.loop=true;
            this.state.animationShare.playSegments([[0,50],[50,100]], true);
        } else {
            this.state.animationShare.loop=false;
            this.state.animationShare.setSpeed(10);
            this.state.animationShare.playSegments([100,110], false);
        }
      }
    });
   

  }

  handleLogoBtnClick(){
    //https://www.dommuseum-hildesheim.de/de/content/zeitenwende1400
    window.open( "https://www.dommuseum-hildesheim.de/de/content/zeitenwende1400", "_top" )
  }

  handleZeitenWendeBtnClick(){
    //https://www.dommuseum-hildesheim.de/de/content/zeitenwende1400
    window.open( "https://www.zeitenwende1400.de", "_self" ) //in https ändern 
  }

  handleDownBtnClick(){
    
    if(this.state.screen===4 || this.state.screen===5) {
      let objDiv;
      if(this.state.screen===4)objDiv = document.getElementById('InfoDiv');
      else objDiv = document.getElementById('ProgrammDiv');
// objDiv.scrollTop-=25;
      if(objDiv.scrollTop<objDiv.scrollHeight - objDiv.clientHeight){
       
       var i = 0;
       var start=objDiv.scrollTop;
       var goal = objDiv.clientHeight-300;//this.state.infoDiv.clientHeight-250; 
       var length = 20; 
      
       this.scrollsmooth(i,start,goal,length,false,this.state.screen);
      }

    }
    
    else this.handleOverlay("DOWN",0,0,false);
  }

  handleUpBtnClick(){
    
    if(this.state.screen===4 || this.state.screen===5) {
      let objDiv;
      if(this.state.screen===4) objDiv = document.getElementById('InfoDiv');
      else  objDiv = document.getElementById('ProgrammDiv');
       // objDiv.scrollTop+=25;

        if(objDiv.scrollTop>0){
          var i = 0;
          var start=objDiv.scrollTop;
          var goal = objDiv.clientHeight-300;//(this.state.infoDiv.clientHeight-250); 
          var length = 20; 
          this.scrollsmooth(i,start,goal,length,true,this.state.screen);
       }

    

    }
    else this.handleOverlay("DOWN",0,0,false);
  }

 

easeOutSine(t, b, c, d,dir) {
if(dir){
return b-(c * Math.sin(t/d * (Math.PI/2)));
}
else return c * Math.sin(t/d * (Math.PI/2)) + b;
}



scrollsmooth(i,start,goal,length,dir,screen) {
if ( i <= length ) { 
let o = this.easeOutSine( i, start , goal, length,dir);
i++;
let objDiv;
if(screen===4)objDiv = document.getElementById('InfoDiv');
if(screen===5)objDiv = document.getElementById('ProgrammDiv');
objDiv.scrollTop = o;
window.requestAnimationFrame( ()=>this.scrollsmooth(i,start,goal,length,dir,screen) ); //Check BrowserSupport RequestAnimationFrame
} else {
// code to be executed when animation finishes
//let {isInfoBottomReached}=this.state;
//this.setState({isInfoBottomReached:!isInfoBottomReached});
}
};

  handleInfoBtnClick(){
    var {screenstates}=this.state;
    Object.keys(screenstates).map(e => {
      if(screenstates[e].show){
        this.setState(prevState => ({downBtnClass:"Btn ShowButton DownButton", screenstates:{...prevState.screenstates,[e]:{...prevState.screenstates[e],isAnimationOverlayZeitsprung:false,isAnimationOverlayInfo:true}}}));
      }
    });
  }

  handleZeitsprung(){
    var {screenstates}=this.state;
    Object.keys(screenstates).map(e => {
      if(screenstates[e].show){
        this.setState(prevState => ({screenstates:{...prevState.screenstates,[e]:{...prevState.screenstates[e],isAnimationOverlayZeitsprung:true,isAnimationOverlayInfo:false}}}));
      }
    });
  }

  handleCloseImpressum(type){
    switch(type){
      case "Impressum":this.setState({isImpressumOpen:false});
                          break;
      case "Datenschutz":this.setState({isDatenschutzOpen:false});
                          break;
    }
  }

  setImpressum(type){
    switch(type){
      case "Impressum":this.setState({isImpressumOpen:true,isDatenschutzOpen:false,mobileMenuIsOpen:false});
                          break;
      case "Datenschutz":this.setState({isDatenschutzOpen:true,isImpressumOpen:false,mobileMenuIsOpen:false});
                          break;
    }
  }

  handleSwipe(dir,event){
    if(this.state.allowSwipe)
    {
      switch (dir){


        case "RIGHT":  let newscreenR;
                        if(this.state.screen<this.state.screens.length-1) newscreenR=this.state.screen+1;
                        else newscreenR=0;
                    //   this.setBrowserLocation(this.state.screens[newscreenR]);
                        this.setScreen(newscreenR,dir);
                        break;

        case "LEFT": let newscreenL;
                      if(this.state.screen>0) newscreenL=this.state.screen-1;
                      else newscreenL=this.state.screens.length-1;
                    // this.setBrowserLocation(this.state.screens[newscreenL]);
                      this.setScreen(newscreenL,dir); 
        case "swiping":this.handleOverlay(event.dir,event.deltaY,event.velocity,true);
                        break;
        case "UP": 
        case "DOWN": 
          
                        this.handleOverlay(event.dir,event.deltaY,event.velocity,false);
                          break;   
           
        }    
    }
  }

  setBrowserLocation(screen){
    switch(screen){
      case "Kampf": 
      case "Liebe": 
      case "Welt": 
      case "Message": 
      case "Info": window.history.pushState({id: '#'+screen}, 'Zeitenwende#'+screen, this.props.domain+"/Dommuseum/"+screen); break;
      case "Programm": window.history.pushState({id: '#'+screen}, 'Zeitenwende#'+screen, this.props.domain+"/Dommuseum/"+screen); break;
    } 
  }

  setBurgerMenuState(newState){
    this.setState({mobileMenuIsOpen:newState});
  }

  showSettings (event) {
    event.preventDefault();
  }

  getMenuStatus(menu){
    let className="FlexTextLeft";

    switch(menu){
      case "Trailer":   if(this.state.isTrailerOpen)this.state.screen===4 || this.state.screen===5 ? className="FlexTextLeft Open":className="FlexTextLeft Active";
                        else this.state.screen!==4 && this.state.screen!==4 ? className="FlexTextLeft Active Closed":className="FlexTextLeft";
                         break;

      case "Info":      if(this.state.isInfoOpen)this.state.screen===4 ? className="FlexTextLeft Active":className="FlexTextLeft Open";
                        else this.state.screen!==4 ? className="FlexTextLeft":className="FlexTextLeft Active Closed";
                         break;
      case "Programm":  if(this.state.isProgrammOpen)this.state.screen===5 ? className="FlexTextLeft Active":className="FlexTextLeft Open";
                        else this.state.screen!==5 ? className="FlexTextLeft":className="FlexTextLeft Active Closed";
                        break;
    }
    

    return className;


  }

  setInfo(pos){

        var scrollToElement;
        var section=null;

        switch(pos){
          case "Ausstellung": scrollToElement=document.getElementById("InfoDiv_start");
                              scrollToElement.style.top="-50px";
                              section=0;
                                break;
          case "Zeit und Ort": scrollToElement=document.getElementById("InfoDiv_Wann");
                                scrollToElement.style.top="-50px";
                                section=1;
                                break;
          case "Führungen":scrollToElement=document.getElementById("InfoDiv_Fuehrungen");
                                scrollToElement.style.top="165px";
                                section=2;
                              break;
          case "Förderer":scrollToElement=document.getElementById("InfoDiv_Foerderer"); 
                              scrollToElement.style.top="-50px";
                              section=3;
                            break;

                              
        }
        if(section!==null ) {
          if(this.state.screen===4){
              scrollToElement.scrollIntoView({block: "start", behavior: "smooth"});
              this.setState(prevState => ({isImpressumOpen:false,
                                          isDatenschutzOpen:false,
                                          screenstates:{...prevState.screenstates,Info:{...prevState.screenstates.Info,section:section}}}));
            } 
            else{
              this.setScreen(4,"RIGHT",section);
              setTimeout(()=>{scrollToElement.scrollIntoView({block: "start", behavior: "smooth"});},1000);
              /*this.setState(prevState => ({screenstates:{...prevState.screenstates,Info:{...prevState.screenstates.Info,section:section}}}));
            */
            }
        }
  }


  setProgramm(pos){
console.log(pos);
    var scrollToElement;
    var section=null;

    switch(pos){
      case "Führungen": scrollToElement=document.getElementById("Fuehrungen");
                          scrollToElement.style.top="-50px";
                          section=0;
                            break;
      case "Junge Besucher": scrollToElement=document.getElementById("JungeBesucher");
                            scrollToElement.style.top="-50px";
                            section=1;
                            break;
      case "Konzert":scrollToElement=document.getElementById("Konzert");
      scrollToElement.style.top="-50px";
                            section=2;
                          break;
      case "Vortrag":scrollToElement=document.getElementById("Vortrag"); 
      scrollToElement.style.top="-50px";
                          section=3;
                        break;
      case "Finissage":scrollToElement=document.getElementById("Finnissage"); 
                          scrollToElement.style.top="-5px";
                          section=4;
                        break;

                          
    }
    if(section!==null ) {
      if(this.state.screen===5){
          scrollToElement.scrollIntoView({block: "start", behavior: "smooth"});
          this.setState(prevState => ({isImpressumOpen:false,
                                      isDatenschutzOpen:false,
                                      screenstates:{...prevState.screenstates,Programm:{...prevState.screenstates.Programm,section:section}}}));
        } 
        else{
          this.setScreen(5,"RIGHT",section);
          setTimeout(()=>{scrollToElement.scrollIntoView({block: "start", behavior: "smooth"});},1000);
          /*this.setState(prevState => ({screenstates:{...prevState.screenstates,Info:{...prevState.screenstates.Info,section:section}}}));
        */
        }
    }
}

 

  render() {
      let {swipeDir,screenstates,screens,screen,downBtnClass,upBtnClass,mobileMenuIsOpen,isHashtagClicked,infoBtnClass,isShareOverlay,socialMedia}=this.state;
      let {device,hashScreen}=this.props;
      let menunames=["Kampf","Liebe","Welt","Message"];
      let infonames=["Ausstellung","Zeit und Ort","Führungen","Förderer"];
      let programmnames=["Führungen","Junge Besucher","Konzert","Vortrag","Finissage"];
      let gridClass;

      if(this.props.showContent)gridClass="Show GridcontainerDommuseum";
      else gridClass="Hide GridcontainerDommuseum";

      let HashtagClassname;
      if (isHashtagClicked)HashtagClassname="Btn HashtagOff Hashtag";
      else HashtagClassname="Btn Hashtag";

      let Logo;
      if(device==="mobileLandscape")Logo=logodommuseum;
      else Logo=LogoZeitenwende;

     // const style=device==="desktop"? {display:none}:{}; 

    return (
    <div>
      <SideBar menuIsOpen={ mobileMenuIsOpen } setImpressum={(type)=>this.setImpressum(type)} setScreen={(newscreenR,dir)=>this.setScreen(newscreenR,dir)} setMenuState={(newSate)=>this.setBurgerMenuState(newSate)}/>

       <div className={gridClass}>    

          {/**  ######## TOP ######## */} 
          <img className={device==="desktop"? "FullImage":"topBackgroundImg" } src={device==="desktop"? "/images/goldback.jpg":"/images/mobile_goldtop.jpg"} alt="Hintergrundbild: Gold"></img> 
                              
       
          
          <img className="LogoZeitenwendeMobil"src={Logo} alt="Logo: Zeitenwende" onClick={()=>this.handleZeitenWendeBtnClick()}></img> 
          {device==="desktop"?    null:  <img className="MobileMenu" src={burgerbtn} alt="Button: MobileMenu" onClick={()=>this.handleBurgerMenuClick()}></img>}
          {/**  ######## SCREEN ######## */}      

      <div className="AnimationBackground" style={device==="desktop"? {}:{display:"none"}}/>
            {/*this.state.isDataReady? null: <ScreenLoading/>*/}
            <div className="ScreenWindow">

            <div className="NextBtn" style={device==="desktop"? {}:{display:"none"}}>
              <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.9 49.3" onClick={()=>this.setScreen(screen+1,"RIGHT")}>
                <polyline className="arrow"  points="1.6,47.6 24.6,24.6 1.6,1.6"/>
              </svg>
              
              </div>
                                  
            <div className="PrvBtn" style={device==="desktop"? {}:{display:"none"}}>
              <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.9 49.3" onClick={()=>this.setScreen(screen-1,"LEFT")}>
              <polyline className="arrow" points="24.6,1.6 1.6,24.6 24.6,47.6"/>
              </svg>
             
              </div>

            
              <div className={isShareOverlay? "ShareOverlay":"Hide ShareOverlay"}>

                    {socialMedia.map((Value,index)=>{
                           return( <Value.Media url="https://www.zeitenwende1400.de" title="Zeitenwende1400" description="Zeitenwende1400 im Dommuseum Hildesheim vom 01.10.2019 bis 02.02.2020" caption="Zeitenwende1400 im Dommuseum Hildesheim vom 01.10.2019" subject="Zeitenwende1400 im Dommuseum Hildesheim" body="Begleite mich in die Ausstellung Zeitenwende zwischen dem 01.10.2019 - 02.02.2020" hashtag="#Zeitenwende1400" hashtags={["Zeitenwende1400", "DommuseumHildesheim",menunames[screen]]} key={index} className={isShareOverlay?"ShareButton":"Hide ShareButton"} > <Value.Icon size={device==="mobile" || device==="mobileLandscape"? 42:64}/></Value.Media>)
                      })
                    }

              </div>
              

                          <ScreenRitter 
                                      handleHashtagBtnClick={()=>this.handleHashtagBtnClick()} 
                                      handleInfoBtnClick={()=>this.handleInfoBtnClick()} 
                                      handleZeitsprung={()=>this.handleZeitsprung()}
                                      isAnimationOverlayZeitsprung={screenstates.Kampf.isAnimationOverlayZeitsprung} 
                                      isAnimationOverlayInfo={screenstates.Kampf.isAnimationOverlayInfo} 
                                      infobtn={infobtn} 
                                      infoBtnClass={infoBtnClass} 
                                      animateOut={screenstates.Kampf.animateOut}
                                      animateIn={screenstates.Kampf.animateIn}
                                      screenClassname={screenstates.Kampf.screenClassname}
                                      direction={swipeDir}
                                      show={screenstates.Kampf.show}
                                      registerScreen={(screenname)=>this.handleScreenLoading(screenname)}
                           />
           
                          <ScreenLiebe 
                                      handleHashtagBtnClick={()=>this.handleHashtagBtnClick()} 
                                      handleInfoBtnClick={()=>this.handleInfoBtnClick()} 
                                      handleZeitsprung={()=>this.handleZeitsprung()}
                                      isAnimationOverlayZeitsprung={screenstates.Liebe.isAnimationOverlayZeitsprung} 
                                      isAnimationOverlayInfo={screenstates.Liebe.isAnimationOverlayInfo} 
                                      infobtn={infobtn} 
                                      infoBtnClass={infoBtnClass} 
                                      animateOut={screenstates.Liebe.animateOut}
                                      animateIn={screenstates.Liebe.animateIn}
                                      screenClassname={screenstates.Liebe.screenClassname}
                                      direction={swipeDir}
                                      show={screenstates.Liebe.show}
                                      registerScreen={(screenname)=>this.handleScreenLoading(screenname)}
                            />
                
                          <ScreenWeltkarte 
                                      handleHashtagBtnClick={()=>this.handleHashtagBtnClick()} 
                                      handleInfoBtnClick={()=>this.handleInfoBtnClick()} 
                                      isAnimationOverlayZeitsprung={screenstates.Welt.isAnimationOverlayZeitsprung} 
                                      isAnimationOverlayInfo={screenstates.Welt.isAnimationOverlayInfo} 
                                      infobtn={infobtn} 
                                      infoBtnClass={infoBtnClass} 
                                      restartAnimation={restart => this.restartAnimation = restart}
                                      animateOut={screenstates.Welt.animateOut}
                                      animateIn={screenstates.Welt.animateIn}
                                      screenClassname={screenstates.Welt.screenClassname}
                                      direction={swipeDir}
                                      show={screenstates.Welt.show}
                                      registerScreen={(screenname)=>this.handleScreenLoading(screenname)}
                            />

                        <ScreenMessage
                                       handleHashtagBtnClick={()=>this.handleHashtagBtnClick()} 
                                       handleInfoBtnClick={()=>this.handleInfoBtnClick()} 
                                       handleZeitsprung={()=>this.handleZeitsprung()}
                                       isAnimationOverlayZeitsprung={screenstates.Message.isAnimationOverlayZeitsprung} 
                                       isAnimationOverlayInfo={screenstates.Message.isAnimationOverlayInfo} 
                                       infobtn={infobtn} 
                                       infoBtnClass={infoBtnClass} 
                                       animateOut={screenstates.Message.animateOut}
                                       animateIn={screenstates.Message.animateIn}
                                       screenClassname={screenstates.Message.screenClassname}
                                       direction={swipeDir}
                                       show={screenstates.Message.show}
                                       registerScreen={(screenname)=>this.handleScreenLoading(screenname)}
                             />

                        <ScreenInfo 
                                       handleHashtagBtnClick={()=>this.handleHashtagBtnClick()} 
                                       handleInfoBtnClick={()=>this.handleInfoBtnClick()} 
                                       handleZeitsprung={()=>this.handleZeitsprung()}
                                       isAnimationOverlayZeitsprung={screenstates.Info.isAnimationOverlayZeitsprung} 
                                       isAnimationOverlayInfo={screenstates.Info.isAnimationOverlayInfo} 
                                       infobtn={infobtn} 
                                       scrollScreen={restart => this.scrollScreenInfo = restart}
                                       infoBtnClass={infoBtnClass} 
                                       animateOut={screenstates.Info.animateOut}
                                       animateIn={screenstates.Info.animateIn}
                                       screenClassname={screenstates.Info.screenClassname}
                                       direction={swipeDir}
                                       show={screenstates.Info.show}
                                       registerScreen={(screenname)=>this.handleScreenLoading(screenname)}
                                       device={this.props.device}
                             />

                        <ScreenProgramm 
                                       handleHashtagBtnClick={()=>this.handleHashtagBtnClick()} 
                                       handleInfoBtnClick={()=>this.handleInfoBtnClick()} 
                                       handleZeitsprung={()=>this.handleZeitsprung()}
                                       isAnimationOverlayZeitsprung={screenstates.Programm.isAnimationOverlayZeitsprung} 
                                       isAnimationOverlayInfo={screenstates.Programm.isAnimationOverlayInfo} 
                                       infobtn={infobtn} 
                                       scrollScreen={restart => this.scrollScreenProgramm = restart}
                                       infoBtnClass={infoBtnClass} 
                                       animateOut={screenstates.Programm.animateOut}
                                       animateIn={screenstates.Programm.animateIn}
                                       screenClassname={screenstates.Programm.screenClassname}
                                       direction={swipeDir}
                                       show={screenstates.Programm.show}
                                       registerScreen={(screenname)=>this.handleScreenLoading(screenname)}
                                       device={this.props.device}
                             />
                             
                        
                        </div>
           

{//Unmount Screens 
          }

             <Swipe handleSwipe={(dir,event)=>this.handleSwipe(dir,event)}/> {/** Handle Swipe nur eine ebene Höher nutzen? */}
             <Impressum className={this.state.isImpressumOpen? "Impressum":"Hide Impressum"} handleCloseBtnClick={(type)=>this.handleCloseImpressum(type)}/>
             <Datenschutz className={this.state.isDatenschutzOpen? "Impressum":"Hide Impressum"} handleCloseBtnClick={(type)=>this.handleCloseImpressum(type)}/>
         {/**  <div className={downBtnClass} src={downbtn} alt="Button: Down" onClick={()=>this.handleDownBtnClick()}>
             <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.9 49.3" onClick={()=>this.setScreen(screen+1,"RIGHT")}>
                <polyline className="arrow"  points="47.6,47.6 24.6,24.6 1.6,47.6"/>
              </svg>
              </div> */}

          

              <div className={downBtnClass} >
                <svg version="1.1" id="DownScrollBtn001" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 10" onClick={()=>this.handleDownBtnClick()}>
                  <polyline className="arrowScroll" points="0,0 11,10 22,0 "/>
                </svg>
              </div>  
              <div className={upBtnClass} >
                <svg version="1.1" id="UpScrollBtn001" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 10" onClick={()=>this.handleUpBtnClick()}>
                  <polyline className="arrowScroll" points="0,10 11,0 22,10 "/>
                </svg>
              </div>  



             
          {/** ######## BOTTOM ######## */}   
              <img className={device==="desktop"? "FullImage":"bottomBackgroundImg"} src={ device==="desktop"? "/images/goldback.jpg":"/images/mobile_goldbottom.jpg"} alt="Hintergrundbild: Gold"></img> 
                         
              

              <div className="FooterBtnDesktop" style={device==="desktop"? {}:{display:"none"}}>
                        <img className="FlexLogo" src={logodommuseum} alt="Button: Dommuseum" onClick={()=>this.handleLogoBtnClick()}></img>
               </div>

                
                      <div className="FooterBtn" > {/** Fotter=Mobile Right=Desktop */}
                        <div className="RightBtnRow">
                              <div className="FlexButton" id="audiobtn"  alt="Button: Audio" onMouseEnter={device==="desktop"?()=>this.handleonMouseEnter("audio"):{}} onMouseLeave={device==="desktop"?()=>this.handleonMouseLeave("audio"):{}} onClick={()=>this.handleAudioBtnClick()}></div> {/** width:auto heigth:100% */}
                              <div className="FlexText" style={device==="desktop"? {}:{display:"none"}}> 
                                      Ton <br/>{this.state.isAudioplay? <span>ausschalten</span>:<span>einschalten</span>}</div>
                          </div>
                          <div className="RightBtnRow">
                            <div className="FlexButton" id="sharebtn" alt="Button: Share" onMouseEnter={device==="desktop"?()=>this.handleonMouseEnter("share"):{}} onMouseLeave={device==="desktop"?()=>this.handleonMouseLeave("share"):{}} onClick={()=>this.handleShareBtnClick()}></div>
                            <div className="FlexText" style={device==="desktop"? {}:{display:"none"}}> Teilen</div>
                         </div>
                          <img className="FlexLogo" style={device==="desktop"? {display:"none"}:{}} src={logodommuseum} alt="Button: Dommuseum" onClick={()=>this.handleLogoBtnClick()}></img>
                        
                      </div>

              
                      <div className="LeftBtnColumn" style={device==="desktop"? {}:{display:"none"}}>
                        <div className="LeftBtnRow">
                            <div  className={this.getMenuStatus("Trailer")}> 
                                <span onClick={()=>{this.setState({isTrailerOpen:!this.state.isTrailerOpen})}}>TRAILER</span><br/>
                                <ul>
                                    {menunames.map((value,index) => {
                                        if(index===screen) return <MenuItem isOpen={this.state.isTrailerOpen} className="Active" key={index} index={index} beforename="#" name={value} setScreen={(name,dir)=>this.setScreen(name,dir)}/> 
                                        else return <MenuItem isOpen={this.state.isTrailerOpen} className="" key={index} index={index} beforename="#"  name={value} setScreen={(name,dir)=>this.setScreen(name,dir)}/> 
                                    })}
                                </ul>
                            </div>
                        </div>
                        
                        <div className="LeftBtnRow">
                            <div className={this.getMenuStatus("Info")}> 
                                <span onClick={()=>{this.setState({isInfoOpen:!this.state.isInfoOpen})}}>INFORMATION</span>
                                <ul>
                                    {infonames.map((value,index) => {
                                        if(index===this.state.screenstates.Info.section) return <MenuItem isOpen={this.state.isInfoOpen} className="Active" key={index} index={index}  mode="info" name={value} setScreen={(pos)=>this.setInfo(pos)}/> 
                                        else return <MenuItem isOpen={this.state.isInfoOpen} className="" key={index} index={index} mode="info" name={value} setScreen={(pos)=>this.setInfo(pos)}/> 
                                    })}
                                </ul>
                           </div>
                        </div>
                        <div className="LeftBtnRow">
                            <div className={this.getMenuStatus("Programm")}> 
                                <span onClick={()=>{this.setState({isProgrammOpen:!this.state.isProgrammOpen})}}>PROGRAMM</span>
                                <ul>
                                    {programmnames.map((value,index) => {
                                        if(index===this.state.screenstates.Programm.section) return <MenuItem isOpen={this.state.isProgrammOpen} className="Active" key={index} index={index}  mode="programm" name={value} setScreen={(pos)=>this.setProgramm(pos)}/> 
                                        else return <MenuItem isOpen={this.state.isProgrammOpen} className="" key={index} index={index} mode="programm" name={value} setScreen={(pos)=>this.setProgramm(pos)}/> 
                                    })}
                                </ul>
                           </div>
                        </div>

                        <div className="LeftBtnRow">
                            <div className={this.state.isImpressumOpen? "ImpressumMenu Active":"ImpressumMenu"}> 
                                <span onClick={()=>{this.setState({isImpressumOpen:!this.state.isImpressumOpen, isDatenschutzOpen:false})}}>IMPRESSUM</span>
                            </div>
                        </div>
                        <div className="LeftBtnRow">
                            <div className={this.state.isDatenschutzOpen? "ImpressumMenu Active":"ImpressumMenu"}> 
                                <span onClick={()=>{this.setState({isDatenschutzOpen:!this.state.isDatenschutzOpen, isImpressumOpen:false})}}>DATENSCHUTZ</span>
                            </div>
                        </div>
                      </div>
                               
        </div>
    </div>
    );
  }
}

export default Dommuseum;
