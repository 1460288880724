import React, { Component } from 'react';
import Lottie from "lottie-web";
import P5Wrapper from "react-p5-wrapper";
import animationData_Herzen from './animationen/herzen.json'


import sketch from './mosaik/sketch_draw'


import Overlay from "./Overlay";
import Marquee from "./Marquee";

import infobtn from './grafiken/info.svg'





  class ScreenMessage extends Component {

    constructor(props) {
      super(props);
      this.state = {
                    screen:"Message",
                    segment:0,
                    animation: null,
                    mounted:0,
                    isHashtagClicked:false,
                    HashtagClassname:"Btn Hashtag"
      };
    }
    
    componentDidMount() {
      console.log("MessageBoard Mount"); 
      this.setState({mounted:1});
   }

   componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      if (this.props.show){
        console.log("Messageboard EINBLENDEN");

      }
      else{
        console.log("Messageboard AUSBLENDEN");
        
        clearTimeout(this.timeout);
        this.timeout = null;
        
        this.setState({segment:0,
                        isHashtagClicked:false,
                        HashtagClassname:"Btn Hashtag"
        });
      }
    }
     else if (this.props.animateOut !== prevProps.animateOut) {
        if(this.props.animateOut){
          clearTimeout(this.timeout);
          this.timeout = null;
          this.setState({
            isAnimationOverlayInfo:false,
            isAnimationOverlayZeitsprung:false})
        }      
      }
      else if (this.props.isAnimationOverlayZeitsprung !== prevProps.isAnimationOverlayZeitsprung) {
        this.setState({isAnimationOverlayZeitsprung:this.props.isAnimationOverlayZeitsprung});
      }
      else if (this.props.isAnimationOverlayInfo !== prevProps.isAnimationOverlayInfo) {
        this.setState({isAnimationOverlayInfo:this.props.isAnimationOverlayInfo});
      }
    }

 
   animationloaded(){
     console.log("Animation geladen.");
     this.props.registerScreen("Message");
     
   }
   handleInfoBtnClick(){

    this.props.handleInfoBtnClick();
    this.setState({
      isAnimationOverlayInfo:true,
      isAnimationOverlayZeitsprung:false
    })

   }

   handleHashtagBtnClick(){

    this.timeout=setTimeout(()=>{this.props.handleZeitsprung(); this.setState({isAnimationOverlayZeitsprung:true});},1000);

    this.setState({isHashtagClicked:true,
                  HashtagClassname:"Btn HashtagOff Hashtag"});
   }
 
   

    
    
    render(){

      let {isAnimationOverlayInfo,isAnimationOverlayZeitsprung,show}=this.props;
let {HashtagClassname}=this.state;



  return (

    <div className={this.props.screenClassname}>     
          {/**   <img className="ScreenImage"src="/images/mosaik.jpg" alt="Bild: Mosaik"></img> */}
          
<div className="ScreenImage">
          <P5Wrapper sketch={sketch} canvaswidth={420} canvasheigth={420} calcMosaik={show}/>
          </div>
          <div className={HashtagClassname}><div className="HashtagText" onClick={()=>this.handleHashtagBtnClick()}>#Message</div></div>
          
            {/** ########## OVERLAY SCREEN */}
            
            
            <Overlay animationStatus={isAnimationOverlayZeitsprung} profile={"Messageboard"}> {/** AnimateIn and Out */}
                    <div className="Anzeige">
                      <Marquee move={show} text=" +++ Künstliche Intelligenz erobert Museum +++ "/>
                    </div>

                    <div className="AnzeigeText">
                    <p>
                        „Gibt es etwas, was Sie mir sagen wollen?“<br/>
                        „Es tut mir leid, meine Antworten sind begrenzt,<br/>
                        Sie müssen die richtigen Fragen stellen.“
                    </p>
                    </div>
                    
                    <div className="AnkuendigungText"><p>Besuche das MessageBoard in der Ausstellung ZeitenWende 1400 vom 01.10.2019 bis zum 02.02.2020 im Dommuseum Hildesheim.</p></div>
                    <div className="InfoRow">
                      <div className="Btn InfoButtonText">mehr Informationen<br/>zum MessageBoard</div>
                      <img className="InfoButton" src={infobtn} alt="Button: Info" onClick={()=>this.handleInfoBtnClick()}></img>
                    </div>      
              </Overlay>

              
         
              <Overlay animationStatus={isAnimationOverlayInfo} profile={"Info"}>
                  <div className="InfoText">
                  <h1>MessageBoard</h1>
                  Als digital Doubles haben es 9 Objekte aus der Ausstellung in die digitale Cloud geschafft. Der interaktive Tisch weiß spannende Anekdoten, Daten und Fakten über die Objekte, doch ihr Wesen liegt im verborgenen. Die einzige Möglichkeit sich den Objekte zu nähern, ist der Dialog mit den Besuchern. Wundern Sie sich nicht, wenn es Sie anspricht und merkwürdige Fragen stellt. Dieses Wesen möchte hinter die Dinge blicken und benötigt dafür unsere Perspektive. Seine Eindrücke verarbeitet es in generativen Mosaiken. Diese stetig wachsende Galerie ist auch online einsehbar. Seien Sie geduldig, Helfen Sie, besuchen Sie es.

                  </div>
              </Overlay>

          
   {/** <div className="Animation" id="herzen"/> **/} {/** Um Animation zu laden muss DIV schon bestehen. Eventuell noch in den Screen verschieben und Animation dort kontrollieren und später laden... */}

    </div>
 
    );
    }
  }

  export default ScreenMessage;