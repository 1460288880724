import React, { Component } from 'react';
import Lottie from "lottie-web";

//import animationData_Herzen from './animationen/herzen.json'
import animationData_Weltkarte from './animationen/weltkarte.json';

import Overlay from "./Overlay";
import Marquee from "./Marquee";

import infobtn from './grafiken/info.svg'

  class ScreenWeltkarte extends Component {



  constructor(props) {
    super(props);
    this.state = {
                  screen:"Weltkarte",
                  segment:0,
                  animation: null,
                  mounted:0,
                  isHashtagClicked:false,
                  HashtagClassname:"Btn Hashtag",
                  isAnimationOverlayMallorca:false,
                  isAnimationOverlayKaiser:false,
                  isAnimationOverlayInfo:false
    };
  }
    
    componentDidMount() {
      console.log("Weltkarte Mount");
      let animation=Lottie.loadAnimation({
       container: document.getElementById("weltkarte"), // the dom element that will contain the animation
       renderer: "svg",
       loop: false,
       autoplay: false,
       animationData: animationData_Weltkarte, // the path to the animation json
       rendererSettings:{ preserveAspectRatio:'xMidYMid slice' }
     });
     animation.addEventListener("loaded_images",()=>this.animationloaded());
     animation.addEventListener("loopComplete",this.animationloop);
     animation.addEventListener("segmentStart",()=>this.segmentstart());

     this.props.restartAnimation(()=>this.handleRestart());
     
     this.setState({animation:animation,mounted:1});
   }

   componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      if (this.props.show){
        console.log("Ferne einblenden");
        this.state.animation.playSegments([0,1],true);
        this.state.animation.stop();
        this.setState({
          segment:0,
          isAnimationOverlayMallorca:false,
          isAnimationOverlayKaiser:false,
          isAnimationOverlayInfo:false});
      }else {
        console.log("Ferne ausblenden");
        clearTimeout(this.timeout);
        this.timeout = null;
        this.setState({
                        segment:0,
                        isHashtagClicked:false,
                        HashtagClassname:"Btn Hashtag"
        });
      }
      
    }

    if (this.props.animateOut !== prevProps.animateOut) {
      if(this.props.animateOut){
        clearTimeout(this.timeout);
        this.timeout = null;
        this.setState({
          isAnimationOverlayMallorca:false,
          isAnimationOverlayKaiser:false,
          isAnimationOverlayInfo:false})
      }
    }

    if (this.props.isAnimationOverlayInfo !== prevProps.isAnimationOverlayInfo) {
      this.setState({isAnimationOverlayInfo:this.props.isAnimationOverlayInfo});
    }

  }

   componentWillUnmount() {
    clearTimeout(this.timeout);
    Lottie.stop("weltkarte");
    this.state.animation.removeEventListener("loaded_images",()=>this.animationloaded());
    this.state.animation.removeEventListener("loopComplete",this.animationloop);
    this.state.animation.removeEventListener("segmentStart",()=>this.segmentstart());
    this.state.animation.destroy();
   }
 
   animationloaded(){
     console.log("Animation geladen.");
     this.props.registerScreen("Welt");
     
   }

   handleHashtagBtnClick(){
    this.state.animation.playSegments([[0,161],[161,161]], true);
    Lottie.play("weltkarte");
   
    this.setState({isHashtagClicked:true,
                  HashtagClassname:"Btn HashtagOff Hashtag"});
   }

   handleRestart(){
    Lottie.pause("weltkarte");
    this.state.animation.playSegments([[0,161],[161,161]], true);
    Lottie.play("weltkarte");
    this.setState({segment:1});
  }
 
   animationloop(){
     console.log("animationloop.");
   }
 
   segmentstart(){
     if(this.props.show){ //safety 
        let segment=this.state.segment;
        segment++;
        if(segment===2){ /** Maria 2.Segment = AnimationLayer einblenden */
          Lottie.pause("weltkarte");
          this.setState({segment:segment,
                        isAnimationOverlayMallorca:true});
          this.timeout=  setTimeout(()=>this.handleDestinationKaiser(), 8000);
        } else if(segment===4){
          Lottie.pause("weltkarte");
          this.setState({segment:segment,
                        isAnimationOverlayKaiser:true});
          this.timeout=  setTimeout(()=>this.handleDestinationMallorca(), 8000);
        }
        else this.setState({segment:segment});
     }
   }

   handleDestinationKaiser(){
     if(this.state.isAnimationOverlayMallorca) //safety
     {
      this.state.animation.playSegments([[161,266],[266,266]], true);
      this.setState({ isAnimationOverlayMallorca:false});
                      Lottie.play("weltkarte");
    }
   }

   handleDestinationMallorca(){
    if(this.state.isAnimationOverlayKaiser) //safety
    {
      this.state.animation.playSegments([[266,360],[360,360]], true);
      this.setState({segment:1,
                      isAnimationOverlayKaiser:false});
      Lottie.play("weltkarte");
    }
   }

   handleInfoBtnClick(){

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.props.handleInfoBtnClick();
    Lottie.pause("weltkarte");

    if(this.state.isAnimationOverlayKaiser){
      this.state.animation.playSegments([508,653],true);
    } else if (this.state.isAnimationOverlayMallorca){
      this.state.animation.playSegments([364,500],true);
      }

    Lottie.play("weltkarte");
      this.setState({
        isAnimationOverlayInfo:true,
        isAnimationOverlayMallorca:false,
        isAnimationOverlayKaiser:false,
        segment:0});
    }

   
    
    render(){
      let {isAnimationOverlayInfo}=this.props;
      let {HashtagClassname}=this.state;


  return (

    <div className={this.props.screenClassname}>     
    
          <div className={HashtagClassname}><div className="HashtagText" onClick={()=>this.handleHashtagBtnClick()}>#Welt</div></div>
          
            {/** ########## OVERLAY SCREEN */}
            
           
            <Overlay animationStatus={this.state.isAnimationOverlayMallorca && !isAnimationOverlayInfo} profile={"Ferne"}> {/** AnimateIn and Out */}
                    <div className="Anzeige">
                      <Marquee move={this.props.show} text=" +++ Mallorca +++ "/>
                    </div>

                    <div className="AnzeigeText">
                    <p> Fernreise ins ferne Espana in bequemer Holzkutsche, inklusive Überquerung der Alpen    
                    </p>
                    <p> Dauer: 8 Monate<br/>
                        für nur 3 Denare*<br/>
                        *Bezahlung vor Antritt der Reise
                    </p>
                    </div>
                    
                    <div className="AnkuendigungText"><p>weitere Reiseinformationen unter www.dommuseum-hildesheim.de.</p></div>
                    <div className="InfoRow">
                      <div className="Btn InfoButtonText">mehr Informationen</div>
                      <img className="InfoButton" src={infobtn} alt="Button: Info" onClick={()=>this.handleInfoBtnClick()}></img>
        {/** <img className="InfoButton" src={infobtn} alt="Button: Info" onClick={()=>this.props.handleInfoBtnClick()}></img>*/}
                    </div>      
              </Overlay>
           


              <Overlay animationStatus={this.state.isAnimationOverlayKaiser && !isAnimationOverlayInfo} profile={"Ferne"}> {/** AnimateIn and Out */}
                    <div className="Anzeige">
                      <Marquee move={this.props.show} text=" +++ Kaisertour +++ "/>
                    </div>

                    <div className="AnzeigeText">
                        <p> Ein Wochenende mit Karl dem Großen
                        in Aachen, inklusive Schlossübernachtung
                        und großem Buffet
                        </p>
                        <p>
                        für nur 2 Denare*<br/>
                        *Bezahlung vor Antritt der Reise    
                        </p>
                    </div>
                    
                    <div className="AnkuendigungText"><p>weitere Reiseinformationen unter www.dommuseum-hildesheim.de.</p></div>
                    <div className="InfoRow">
                      <div className="Btn InfoButtonText">mehr Informationen</div>
                      <img className="InfoButton" src={infobtn} alt="Button: Info" onClick={()=>this.handleInfoBtnClick()}></img>
                    </div>      
              </Overlay>

              
         
              <Overlay animationStatus={isAnimationOverlayInfo} profile={"Info"}>
                  <div className="InfoText">
                  <h1>Zeitzer Weltkarte</h1>
                  In der Stiftsbibliothek Zeitz (Sachsen-Anhalt) wird die sogenannte „Zeitzer Weltkarte“ aus dem Jahr 1470 aufbewahrt. Neben der Weltkarte des Andreas Walsperger in der Bibliotheca Apostolica Vaticana ist sie die einzige derartige Karte aus dem deutschen Sprachraum, die sich erhalten hat. Im Unterschied zu unseren heutigen Karten ist sie gesüdet, d.h. aus heutiger Sicht steht alles auf dem Kopf. Das Kartenbild zeigt die damals bekannte Welt kreisrund, mit Jerusalem als Zentrum. Nur drei Kontinente waren bekannt: Afrika in der oberen Hälfte, Europa unten und Asien unten links mit dem Mittelmeer als Grenze.
                  </div>
              </Overlay>

          
    <div className="Animation" id="weltkarte"/> {/** Um Animation zu laden muss DIV schon bestehen. Eventuell noch in den Screen verschieben und Animation dort kontrollieren und später laden... */}

    </div>
 
    );
    }
  }

  export default ScreenWeltkarte;