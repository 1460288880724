
  import React, { Component } from 'react';

import { slide as Menu } from "react-burger-menu";

//export default props => {


  class SideBar extends Component {
    stateChange(MenuState){
     
      this.props.setMenuState(MenuState.isOpen);
    }

    setScreen(screen){
        this.props.setScreen(screen,"RIGHT");
    }

    render(){

      var isMenuOpen = function(state) {
       
      };



  return (
    <Menu right disableAutoFocus customBurgerIcon={ false } isOpen={ this.props.menuIsOpen } onStateChange={ (MenuState)=>this.stateChange(MenuState) }>
   
      <div className="menu-item" onClick={()=>this.setScreen("Kampf")}>
        #Kampf
      </div>

      <div className="menu-item" onClick={()=>this.setScreen("Welt")}>
        #Welt
      </div>

      <div className="menu-item" onClick={()=>this.setScreen("Liebe")}>
        #Liebe
      </div>

      <div className="menu-item" onClick={()=>this.setScreen("Message")}>
        #Message
      </div>
      <div className="menu-item" onClick={()=>this.setScreen("Info")}>
      #Info
      </div>
      <div className="menu-item" onClick={()=>this.setScreen("Programm")}>
      #Programm
      </div>
      <div className="menu-item" onClick={()=>this.props.setImpressum("Impressum")}>
      #Impressum
      </div>
      <div className="menu-item" onClick={()=>this.props.setImpressum("Datenschutz")}>
      #Datenschutz
      </div>
    </Menu>
  );
}
};

export default SideBar;