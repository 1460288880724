import React, { Component } from 'react';
//import './Overlay.css';

  class Impressum extends Component {
    render(){
     

  return (

    <div className={this.props.className}> {/** AnimateIn and Out */}
       <div className="ImpressumButton" onClick={()=>{this.props.handleCloseBtnClick("Impressum")}}>X</div>
       <p>Das vorliegende Informationsangebot wird angeboten vom Dommuseum Hildesheim und dem Niedersächsischen Landesmuseum Hannover.</p>
        <p>Für das vorliegende Informationsangebot zeichnet verantwortlich im Sinne der einschlägigen gesetzlichen Bestimmungen:</p>

        <p><strong>Niedersächsisches Landesmuseum Hannover</strong><br /></p>
        <p>vertreten durch die Direktorin Dr. Katja Lembke<br />
Willy-Brandt-Allee 5<br/>
30169 Hannover</p>

 <p><strong>Bistum Hildesheim<br />
Dommuseum Hildesheim</strong></p>
<p>vertreten durch die Direktorin Prof. Dr. Claudia Höhl<br />
Domhof 18-21<br />
31134 Hildesheim</p>
<p>✆ (05121) 307 760</p>
<p>E-Mail <a href="mailto:dommuseum@bistum-hildesheim.de">dommuseum@bistum-hildesheim.de</a></p>
<br />
<p>Inhaltlich verantwortlich: Dr. Claudia Höhl und Dr. Katja Lembke</p>
<br />
<p>Konzept: Jan Hellwig</p>
<p>Gestaltung: mann + maus KG</p>
<p>Umsetzung: Rabot Rummel GbR</p>

<p>Musik: "Je vivroie liement" von Guillaume de Machaut<br/>
Arrangement/Mix: Oliver Gies<br/>
Gesang: Anja Ritterbusch 
</p>

<br />
<p>Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
<p>Urheberrecht: Sämtliche auf <a href="http://www.zeitenwende1400.de">www.zeitenwende1400.de</a> veröffentlichten Inhalte sind urheberrechtlich oder durch sonstige Rechte geschützt. Die Nutzungsrechte stehen dem Dommuseum Hildesheim und Landesmuseum Hannover zu. Ohne vorherige schriftliche Genehmigung sind Nutzungen der Inhalte insgesamt oder in Teilen unzulässig. Die Genehmigung zur Nutzung kann erbeten werden bei den Rechteinhabern. Eine Genehmigung reicht nur so weit, wie dem Dommuseum Hildesheim oder dem Niedersächsischen Landesmuseum Hannover Rechte übertragen wurden.</p>
         
         
    </div>

    );
    }
  }

  export default Impressum;