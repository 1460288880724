import React, { Component } from 'react';

import CountUp from 'react-countup';
import Overlay from "./Overlay";
import LogoDomWhite from './grafiken/Logo_Dommuseum_white.svg'
import { useCountUp } from 'react-countup';



const CompleteHook = () => {
  const { countUp, start, pauseResume, reset, update } = useCountUp({
    start: 0,
    end: 88,
    delay: 1,
    duration: 5,
    onReset: () => console.log('Resetted!'),
    onUpdate: () => console.log('Updated!'),
    onPauseResume: () => console.log('Paused or resumed!'),
    onStart: ({ pauseResume }) => console.log(pauseResume),
    onEnd: ({ pauseResume }) => console.log(pauseResume),
  });

  //setTimeout(()=>reset(), 8000);
  return (

    <span>
      {countUp}
    </span>
  );
};

class ScreenProgramm extends Component {



  constructor(props) {
    super(props);
    this.state = {
      isAnimationOverlayCount: true,
      isAnimationOverlayInfo: false,
      isAnimationOverlayInfob: false,
      infoDiv: null,
      counterTextclassName: "CounterText"
    };
  }

  componentDidMount() {
    this.props.scrollScreen((dir, deltaY,velocity) => this.handleScroll(dir, deltaY,velocity));
    const objDiv = document.getElementById('ProgrammDiv');

    this.setState({
      infoDiv: objDiv,
      isAnimationOverlayInfo: true
    });
  }

  handleInfoIn() {
    this.timeout = setTimeout(() => this.setState({ isAnimationOverlayCount: false }), 250);
    this.setState({
      isAnimationOverlayInfo: true
    });
  }

  componentDidUpdate(prevProps) {

    
    if (this.props.show !== prevProps.show) {

      if (!this.props.show) {
        clearTimeout(this.timeout);
        this.state.infoDiv.scrollTop = 0;
        this.state.infoDiv.style.transitionDuration = "1ms";
        this.setState({
          isAnimationOverlayCount: true,
          isAnimationOverlayInfo: false,
          isAnimationOverlayInfob: false,
          isInfoBottomReached: false,
          counterTextclassName: "CounterText"
        })

      }
      else if (this.props.show) {
        this.state.infoDiv.style.transitionDuration = "15000ms";
        this.timeout = setTimeout(() => this.setState({ counterTextclassName: "fade CounterText" }), 500);
        //this.timeout=  setTimeout(()=>this.handleInfoIn(), 5000);
      }
    }
    


  }

  componentWillUnmount() {


  }

  handleScroll(dir, deltaY,velocity) {
    console.log("handleScrollProgramm");
    console.log("handleScroll:"+deltaY+" vel:"+velocity);
    
    this.state.infoDiv.scrollTop += deltaY * velocity*.3;//0.03;
  
  }

  





  render() {

    let { isAnimationOverlayZeitsprung } = this.props;
    let { isAnimationOverlayCount, isAnimationOverlayInfo, isAnimationOverlayInfob } = this.state;

    let screenClassname;
    if (!this.props.show) {
      screenClassname = "ScreenHidden Screen";
    }
    else if (this.props.animateOut) {
      if (this.props.direction == "LEFT") screenClassname = "ScreenOutLeft Screen";
      else screenClassname = "ScreenOutRight Screen";
    } else if (this.props.animateIn) {
      if (this.props.direction == "LEFT") screenClassname = "ScreenInRight Screen";
      else screenClassname = "ScreenInLeft Screen";
    }
    else screenClassname = "Screen";
    //const theDiv = document.querySelector('#AusstellungInfo');
    //theDiv.scrollTop = Math.pow(10, 10);



    return (

      <div className={this.props.screenClassname}>

        <img className="CenterImage" src="/images/RitterGeorg.png" alt="Bild: Madonna" />
        {/** <Overlay animationStatus={isAnimationOverlayCount} profile={"Info"}>*/}
<div className="AusstellungProgrammMain"  id="ProgrammDiv">
        <div className="AusstellungProgramm">
       
        <span className="ScrollToElement" id="Fuehrungen" />
                <h1>Rahmenprogramm</h1>
                
                <h3>Öffentliche Führungen</h3>
                <h4>Allgemeine Überblicksführung</h4>
                  <p>
                  Samstag, Sonntag, feiertags und an<br/>
                  Aktionstagen | <b>14.30 Uhr</b> <br/>
                  4 Euro, ermäßigt 3 Euro pro Teilnehmer
                  </p>

                <h4>Foto-Tour Zeitenwende 1400</h4>
                <p>
                  Mit fachkundiger Begleitung geht es durch
                  die Ausstellung. Es gibt Hinweise zu großartigen
                  Objekten, ungewöhnliche Perspektiven
                  und spannende Hintergrundinformationen.
                  Egal ob mit Kamera oder Smartphone
                  ausgerüstet, geht es auf Jagd nach den
                  schönsten Motiven für den Instagram-Post
                  genauso wie für das eigene Fotoalbum. <br/><br/>
                 <b> 17. November 2019 | 15.30 Uhr<br/>
                  11. Januar 2020 | 11.00 Uhr<br/><br/></b>
                  Kostenbeitrag 6 Euro pro Teilnehmer,
                  zzgl. Eintritt<br/>
                  Die Teilnehmerzahl ist begrenzt.
                  Kameras und sonstige Ausrüstung müssen
                  selbst mitgebracht werden.
                  </p>

                  <h4>Kuratoren-Führung</h4>
                  <p>
                  Die Kuratoren stellen die Ausstellung vor.<br/><br/>
                  <b>17. Oktober 2019 Prof. Dr. Claudia Höhl<br/>
                  14. November 2019 Dr. Gerhard Lutz<br/>
                  16. Januar 2020 Dr. Felix Prinz</b><br/><br/>
                  jeweils 16.00 Uhr
                  Die Teilnahme an der Führung ist kostenfrei,
                  Eintritt wird erhoben
                  </p>

                  <h4>Themen-Führung</h4>
                  <p>
                  <b>24. Oktober 2019</b><br/>
                  Prof. Dr. Dr. Jörg Bölling führt zum Thema
                  „Pilgern im Mittelalter“<br/><br/>
                  <b>21. November 2019</b><br/>
                  Die Restauratorin Roksana Jachim
                  führt zum Antependium aus dem
                  Trinitatis-Hospital in Hildesheim und
                  gibt Einblick in ihre Arbeit<br/><br/>
                  <b>jeweils 17.00 Uhr</b><br/>
                  Führungsgebühr 4 Euro, zzgl. Eintritt
                  </p>

                <p> Die Teilnehmerzahl ist jeweils begrenzt. Tickets sind im Domfoyer oder im 
                  
                  <a href="https://dmh.visitate.net/app/Shopping?ref=shp867862378"> - Online-Shop - </a> erhältlich.
                </p>

                <div  id="JungeBesucher"/>
          <div className="AusstellungPrgramm" >
        

                <h3>Zeitenwende für Junge Besucher</h3>
                <h4>Freier Eintritt bis 25 Jahre.</h4>

           
                <h4>Interaktive Spiel- und Multimediastationen</h4>
                <p>
                Das „Messageboard“ lädt zur spielerischen Auseinandersetzung mit der Ausstellung ein. Von der „künstlichen Intelligenz“ unseres Spieltischs werden Objekte und Themen aus der Ausstellung auf faszinierende Weise dargestellt und im Dialog mit den Besuchern intensiv betrachtet.<br/><br/>
                Klangstationen lassen die Ausstellungsobjekte und die Zeit um 1400 lebendig werden.
                </p>
              
                <h4>Spannende Workshops</h4>
                <p>
                    <b>It’s a material world</b> – das Material, aus dem die Kunst ist <br/>
                    Am Beispiel ausgewählter Objekte lernen wir die Materialität und Verarbeitung von historischen Kunstwerken kennen. Dazu legen wir selbst eine Sammlung mit Informationen und Materialproben an.
                </p>
                <br/>
                <p>
                  <b>Pilger, Künstler, Fabeltiere. Reisen um 1400</b><br/>
                  Die Kunstwerke zeugen von der Mobilität in der damaligen Zeit. Die politisch, religiös oder wirtschaftlich motivierten Reisen und Wanderbewegungen vollziehen wir auf einer mittelalterlichen Weltkarte nach.
                </p>
                <br/>
                <p>
                    <b>Die unglaublichen Geschichten</b><br/>
                    Die Objekte in der Ausstellung erzählen vielfältige Geschichten, biblische Erzählungen, Heiligenlegenden und Geschichten aus dem Kontext ihrer Entstehung. Gemeinsam erzählen wir die Geschichten neu oder weiter.

                </p>
                <br/>
                <p>
                    <b>News und Fakenews</b><br/>
                    Ausgehend vom „Newsticker“ zum Weltgeschehen um 1400 und den Objekten in der Ausstellung recherchieren wir zu wichtigen Themen der „Zeitenwende 1400“.
                </p>
                <br/>
                <p>
                  … diese und weitere Workshops bieten neue Blicke auf die Kunstwerke und Themen in der Ausstellung, von Materialkunde über künstlerische Techniken der spannenden Zeit um 1400 bis zu den unglaublichen Geschichten dazu.  
                </p>

                <p>
                    Kostenbeitrag 4 Euro pro Teilnehmer, für Schulklassen und Gruppen geeignet, (min. 10 Teilnehmer erforderlich)<br/>
                    Information und Buchung unter T 05121-307770 oder dom-information@bistum-hildesheim.de

                </p>


                <h4>#Liebe#Glaube#Kunst <br/>Philosophier-Lounge für Kinder</h4>
                <p>
                <b>19.10.</b> PD Dr. Katrin Wille, Universität Hildesheim<br/>
                <b>02.11.</b> Studentisches Kollektiv „Philosophieren mit Kindern“, Universität Hildesheim<br/>
                <b>23.11.</b> Hans-Joachim Müller, Universität Oldenburg<br/>
                <b>14.12.</b> Swantje Saß, Universität Hildesheim<br/>
                <b>18.01.</b> Prof. Dr. Bettina Uhlig, Universität Hildesheim<br/><br/>
                Samstagvormittag | jeweils 11.00 Uhr, 90 Minuten<br/>
                Kostenbeitrag 4 Euro pro Teilnehmer, Für Kinder von 8–11 Jahren<br/>
                Die Teilnehmerzahl ist begrenzt, Tickets im Domfoyer und im <a href="https://dmh.visitate.net/app/Shopping?ref=shp867862378&event=showGuidedTourCalendar&cat=1640&cat2=1428&cat3=2&param=6442"> - Online-Shop - </a> erhältlich.
                </p>

                <h4>Führungen</h4>
                <p>
                Altersgerechte Führungen für Schüler-, Kinder-, Jugend-, Studentengruppen<br/>
                ermäßigt 70 Euro pro Gruppe<br/>
                Kurzführung Zeitenwende: 40 Euro pro Gruppe<br/>
                Termine für Führungen und Workshops nach Vereinbarung<br/>
                Information und Buchung unter T 05121-307770 oder dom-information@bistum-hildesheim.de
                </p>
          </div>
          <div  id="Konzert"/>
          <div className="AusstellungPrgramm" >
        
              <h3>Konzert</h3>
              <h4>Duo Scivias: Mittelalterliche Musik zur Adventszeit</h4>
       
          <p>
              Eva-Maria Karras und Hans-Dieter Karras
              Gesang begleitet von mittelalterlichen
              Instrumenten<br/>

             <b> 13. Dezember 2019 | 18.00 Uhr</b>
              Tickets sind zum Preis von 15 Euro, ermäßigt
              10 Euro im Domfoyer oder 
            <a href="https://dmh.visitate.net/app/Shopping?mod=ShopContent&event=showCategory&ref=shp867862378&cat=1639"> - im Onlineshop - </a>erhältlich.
          </p>
          </div>  
          <div  id="Vortrag"/>
          <div className="AusstellungPrgramm" >
        
              <h3>Vortrag</h3>
              <h4>Ein vergessenes Kunstwerk</h4>
            
              <p>
              Dr. Petra Marx spricht zum Thema
              „Ein vergessenes Kunstwerk der
              niedersächsischen Spätgotik:
              Das Magdalenen-Retabel aus dem
              Hildesheimer Reuerinnenkloster“<br/><br/>
              <b>22. Januar 2020 | 18.00 Uhr | Dombibliothek</b><br/>
              Eintritt frei
              </p>
          </div>

          <div  id="Finnissage"/>
          <div className="AusstellungPrgramm">
        
              <h3>Finissage</h3>
              <h4>2. Februar 2020 | 15.00 Uhr</h4>
              <br/><br/><br/><br/><br/><br/>  <br/><br/><br/><br/><br/><br/>  <br/><br/><br/><br/><br/><br/> <br/><br/><br/><br/><br/><br/>
          </div>
        
          </div>
          </div>
      </div>
    );
  }
}
export default ScreenProgramm;