import React from "react";
import { useSwipeable } from "react-swipeable";


const RIGHT = "RIGHT";
const LEFT = "LEFT";
const UP = "UP";
const DOWN = "DOWN";

const initialState = { pos: 0, sliding: false, dir: RIGHT };

const Swipe = props =>{ 
    
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const numItems = React.Children.count(props.children);
    /*props.handleSwipe*/

    const slide = (dir,event) => {
        props.handleSwipe(dir,event);
      dispatch({ type: dir, numItems });
      setTimeout(() => {
        dispatch({ type: "stopSliding" });
      }, 50);
    };
    const handlers = useSwipeable({
      onSwipedLeft: (event) => {slide(LEFT,event)},
      onSwipedRight: (event) => {slide(RIGHT,event)},
      onSwipedUp:(event)=>{slide(UP,event)},
      onSwipedDown:(event)=>{slide(DOWN,event)},
      onSwiping:(event)=>{slide("swiping",event)},
      preventDefaultTouchmoveEvent: true, //true?
      trackMouse: true
    });

    return (
      <div className="SwipeArea" {...handlers}>
      </div>
    );
  };
  

  function reducer(state, { type, numItems }) {
    switch (type) {
      case "reset":
        return initialState;
      case LEFT: 
        return {
          ...state,
          dir: LEFT,
          sliding: true,
          pos: state.pos === 0 ? numItems - 1 : state.pos - 1
        };
      case RIGHT:
        return {
          ...state,
          dir: RIGHT,
          sliding: true,
          pos: state.pos === numItems - 1 ? 0 : state.pos + 1
        };
        case UP:
        return {
          ...state,
          dir: UP,
          sliding: true,
          pos: state.pos === numItems - 1 ? 0 : state.pos + 1
        };
        case DOWN:
        return {
          ...state,
          dir: DOWN,
          sliding: true,
          pos: state.pos === numItems - 1 ? 0 : state.pos + 1
        };
      case "stopSliding":
        return { ...state, sliding: false };
      default:
        return state;
    }
  }
  export default Swipe;
 