
  import React from 'react';
  import Ticker from 'react-ticker';

  import './Marquee.css';

 

        const Marquee = props =>{ 
        return (
<Ticker move={props.move} mode="smooth">
{({  }) => (
  <h1>
{props.text }
</h1>
)}

</Ticker>
                    
                );
    }


  export default Marquee;