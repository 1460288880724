export default function sketch (p) {

  var datapath = 'database';
  var calcMosaik=false;
  const _interval=3500;
  var timeout; 
  var timeoutPromises;
  var firststart=true;
  var drawcount=0;

  //ar mosaik = [];

  var dm_Mosaik = function(){

    this.blocks = {path:[],keywords:[]};
    this.images = [];
    
    this.start = function(){
      //calcMosaik=props.calcMosaik;
      let r = Math.round(Math.random()*55);
      timeoutPromises=setTimeout(()=>{mosaik.loadMosaik(datapath+"/mosaik/"+r+".json")},1000);
    //  let r = Math.round(Math.random()*55);
    //  mosaik.loadMosaik(datapath+"/mosaik/"+r+".json");
    }

    this.setData = function(_data){
      this.blocks = _data;
      this.loadImages();

    }

    this.loadMosaik = function(_path=""){
      p.loadJSON(_path, (results)=>{
        this.setData(results); // bessere lösung???
      });
    }
  
    this.loadBlock=function(b,image){
    return new Promise((resolve, reject)=>{
          b.img = p.loadImage(image, () => {
            resolve(b.alpha);
          },
          ()=> {
            reject("Error loading:"+image);
          });

        })
    }

    this.loadImages = function(){
      let promises=[];
      let interval;
      if(firststart)interval=1000;
      else interval=_interval;

      for (let i = 0; i < this.blocks.path.length; i++) {
        let b = this.blocks.path[i];
        let kw = this.blocks.keywords[b[4]];
        b.alpha = null;
        let ladeVersatz=Math.round((interval/this.blocks.path.length)*i);

        promises.push(new Promise((resolve, reject)=>{
          
          setTimeout(()=>{this.loadBlock(b,datapath+"/blocks/"+kw+"/"+b[0]+".jpg").then(value => { 
            if(calcMosaik || firststart)resolve(value);
            else reject("#Message -Stoped- because Screen Offline");

          }).catch(error => { 
            reject(error)
          });
        },ladeVersatz)

                
         }))
    }

    Promise.all(promises).then(values => {
        for(let i=0; i<promises.length;i++)
        {
          let b = this.blocks.path[i];
          b.alpha=0;
        }
        
        if(!firststart)
        {
         // let r = Math.round(Math.random()*55);
         //  timeoutPromises=setTimeout(()=>{mosaik.loadMosaik(datapath+"/mosaik/"+r+".json")},_interval);
        }
        else {firststart=false;}
      }, error => {
        console.log(error);
      });
  }}

  function nextMosaik(){
    if(calcMosaik && !firststart)
    {
      let r = Math.round(Math.random()*55);
      mosaik.loadMosaik(datapath+"/mosaik/"+r+".json");
    }
  }

  var mosaik = new dm_Mosaik();

  p.myCustomRedrawAccordingToNewPropsHandler = function (props) {
      if(calcMosaik!==props.calcMosaik)
      {
        calcMosaik=props.calcMosaik;
        if(props.calcMosaik){ drawcount=0;timeout=setTimeout(nextMosaik,1000);}
        else {
          drawcount=0;
              clearTimeout(timeout); 
              clearTimeout(timeoutPromises);
              timeoutPromises=null;
              timeout=null;
            };
      }
  };

  p.setup = function (props){
   // p.createCanvas(p.windowWidth, p.windowHeight);  // 
   p.createCanvas(1230, 1230);  // 
    p.noStroke();
    p.background(0);
    mosaik.start(8500);
  }

  
  p.draw =function() {
    if(mosaik.blocks.path.length >0){

      let skip = 0;
      for (let i = 0; i < mosaik.blocks.path.length; i++) {
        let aa = mosaik.blocks.path[i].alpha;
        if(aa===30){
                    drawcount++;//console.log(drawcount);
                    if(calcMosaik && drawcount===1681) { //Mosaik fertig gezeichnet
                      drawcount=0;
                      let r = Math.round(Math.random()*55);
                      timeoutPromises=setTimeout(()=>{mosaik.loadMosaik(datapath+"/mosaik/"+r+".json")},_interval);
                    }
        };
        if (aa === null){break};
        if (skip > 10){break};
       
        
        if (aa == 0){skip++};

        if(aa >= 0 && aa< 31 ) {
          if (aa<31){aa = aa+1};
          mosaik.blocks.path[i].alpha = aa;

          let xx = mosaik.blocks.path[i][1]*30;
          let yy = mosaik.blocks.path[i][2]*30;
          let rr = mosaik.blocks.path[i][3]*90;
          let img = mosaik.blocks.path[i].img;

          p.fill(0, 0);
          p.rect(0, 0, p.width, p.height);
          p.fill(0);
          p.push();
                
          p.translate(xx+7.5, yy+7.5);
          p.angleMode(p.DEGREES)

          p.rotate(rr)
          if(img)p.image(img, aa*-0.5, aa*-0.5,aa,aa);
          
          p.pop(); 
        }  
      }
      }
  }
}





